import {UPLOAD_ATTACHMENT,DOWNLOADS} from "../actionTypes"
import { uploadAttachments,downloadFile } from "../../../api/uploadAttachment/attachment.service";

const uploadAttachmentRequest = ()=>({type: UPLOAD_ATTACHMENT.REQUEST});
const uploadAttachmentFailure = (error)=>({type: UPLOAD_ATTACHMENT.FAILURE, error})
const uploadAttachmentSuccess = ()=>({type: UPLOAD_ATTACHMENT.SUCCESS})


export const uploadAttachment = (payload)=>{
    return (dispatch, getState)=>{
        dispatch(uploadAttachmentRequest())
        return uploadAttachments(payload)
        .then(response => {
            dispatch(uploadAttachmentSuccess())
            return Promise.resolve({res:response,status:true})
        })
        .catch(error => {
            dispatch(uploadAttachmentFailure(error?.message))
            return Promise.resolve({error:error?.message,status:false})
        })
    }
}



const downloadRequest = () => ({ type: DOWNLOADS.REQUEST })
const  downloadfailure = (error) => ({ type: DOWNLOADS.FAILURE, error })
const  downloadSuccess = (data)=>({type: DOWNLOADS.SUCCESS, data})


export const downloadFiles = (originalFileName,uniqueFileName)=>{
    return (dispatch, getState)=>{        
        dispatch( downloadRequest())
        return downloadFile(originalFileName,uniqueFileName)
        .then(response => { 
            dispatch( downloadSuccess(response))
            return Promise.resolve({file:response,status:true})
        })
        .catch(errorMessage => {
            dispatch(downloadfailure(errorMessage))
            return Promise.resolve({error:errorMessage,status:false})
        })
    }
}