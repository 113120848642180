import { Field, Form, Formik,useField,ErrorMessage } from "formik";
import React, { useId, useState } from "react";
import * as Yup from "yup";
import Asterisk from "../../../../components/asterisk/Asterisk";
import { BackButton, Submit } from "../../../../components/button/buttons";
import { TextInput } from "../../../../components/input/inputs";
import IMAGES from "../../../../assets/images/images";  
import { FormFieldLabel as Label,FormErrorLabel as ErrorLabel } from "../../../../components/label/Labels";
import { Sidebar } from "../../../../components/sidebar/Sidebar";
import styles from "./NewUser.module.css";
import { Alert } from "../../../../components/alert/alerts";
import { USER_TYPE } from "../../../../utils/constants/userConstants";
import * as DOMPurify from 'dompurify';
import { capitalizeWords } from "../../../../utils/utils";
const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};

const TextField = ({ label, placeholder, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? styles.inputError
    : null;
  return (
    <FieldWrapper>
      <div>
        <Label text={label} />
        <Asterisk />
      </div>
      <TextInput  {...field}  {...props} placeholder={placeholder}  style={inputStyle}   onChange={props.onChange} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const PhoneNumberTextField = ({ label, placeholder, ...props }) => {
  return (
    <FieldWrapper>
      <div>
        <Label text={label} />
      </div>
      <TextInput {...props} placeholder={placeholder} />
    </FieldWrapper>
  );
};

const CheckBox = ({ name, value, label }) => {
  const id = useId();
  return (
    <div className={styles.radioField}>
      <Field
        id={id}
        className={styles.radioInput}
        type="checkbox"
        name={name}
        value={value}
      />
      <label htmlFor={id} className={styles.radioLabel}>
        {label}
      </label>
    </div>
  );
};
const ButtonWrapper = ({ children }) => {
  return <div className={styles.buttonContainer}>{children}</div>;
};



const NewUser = ({cpName,primaryContact,...props}) => {
  const {functionalRoles}=props;
  const [showAddUser, setShowAddUser] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  let [isAlert, setIsAlert] = useState(false);
  const [message, setMessage] = useState({
    message: "",
    image: IMAGES.success
  });

  let initialValues={
    email: "",
    fullName: "",
    phoneNumber: null,
    assignModule: "manual",
    userType:"customer",
    cpName:cpName,
    functionalRoles:[]
  }


  const getEmailDomain=(email)=>{
    return email?email.split("@")[1]:"";
  }
  
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").max(100,"Email must be at most 100 characters").required("Required").test(
      {
          name:'Domain Validate',
          message:`User email domain must be same as the primary contact email domain (${getEmailDomain(primaryContact?.email)})`,
          test:function(value){
              let primaryEmailDomain= getEmailDomain(primaryContact?.email);
              let userEmailDomain=getEmailDomain(value);
              return primaryEmailDomain === userEmailDomain
          }
      }
  ),
    fullName: Yup.string()
      .min(2, "Too Short!")
      .max(100,"Name must be at most 100 characters")
      .required("Required"),
    phoneNumber: Yup.string().matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Invalid mobile number").nullable(true),
    functionalRoles:Yup.array().min(1,"Please select at least one role.")
  });

  const close = () => setShowAddUser(false);
  
  return (
    <div>
      <button
        onClick={() => setShowAddUser(true)}
        className={styles.addButton}
      >
        Add New User
      </button>

      {showAddUser && (
        <Sidebar
          isOpen={showAddUser}
          headerTxt="Add New User"
          onClose={close}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange
            onSubmit={async (values, { resetForm }) => {    
              values.fullName = DOMPurify.sanitize(values?.fullName);
              values.phoneNumber = null; //DOMPurify.sanitize(values?.phoneNumber);
              values.cpName = DOMPurify.sanitize(values?.cpName);  
              values.email = DOMPurify.sanitize(values?.email);             
              let obj = JSON.parse(JSON.stringify(values));
             
              delete obj.assignModule; 
              setIsSubmit(true);
              props.createUser(obj).then((result) => {
                if (result.status) {
                  setMessage({
                    message: "New user added successfully!",
                    image: IMAGES.success
                  });
                  setMessage({
                    message: "New user added successfully!",
                    image: IMAGES.success
                  });
                  setIsSubmit(false);
                  resetForm({ values: "" });
                  close();
                  props.openAlertPopup({
                    message: "New user added successfully!",
                    image: IMAGES.success
                  });
                //  setIsAlert(true);
                //  Alert.showAlert(message.message, message.image);
                  let searchKey = "";
                  let type = USER_TYPE.CUSTOMER;
                  let custID = cpName;
                  let next=null;
                  props.getUser(type, custID, searchKey,next);
                } else {
                  setIsSubmit(false);
                  let error_msg = result?.error;
                  close();
                  setMessage({
                    message: error_msg,
                    image: IMAGES.error
                  });
                  props.openAlertPopup({
                    message: error_msg,
                    image: IMAGES.error
                  });
               //   Alert.showAlert(message.message, message.image);
                 // setIsAlert(true);
                }
              })
                .catch((error) => {
                  setIsSubmit(false);
                  close();
                  setMessage({
                    message: "Something went wrong.",
                    image: IMAGES.error
                  });
                  props.openAlertPopup({
                    message: "Something went wrong.",
                    image: IMAGES.error
                  });
                 // Alert.showAlert(message.message, message.image);
                //  setIsAlert(true);
                });
            }}
          >
            {({
              isValid,
              dirty,
              setFieldValue,
              values,
              handleChange,
              handleBlur,
            }) => (
              <Form className={styles.form}>
                <fieldset>
                  <TextField
                    label="Email Address"
                    name="email"
                    type="text"
                    placeholder="Input text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <TextField
                    label="Full Name"
                    name="fullName"
                    type="text"
                    placeholder="Input text"
                    //onChange={handleChange}
                    onBlur={handleBlur}
                    onChange={(e)=>{
                      setFieldValue("fullName",capitalizeWords(e.target.value));
                      }}
                  />
                  {/*<PhoneNumberTextField
                    label="Mobile number"
                    name="phoneNumber"
                    type="text"
                    placeholder="XXXXX XXXXX"
                    onChange={handleChange}
                    onBlur={handleBlur}
            />*/}

                  <div>
                    <h2 className={styles.assign}>Assign Modules</h2>
                    {/* <div className={styles.radioGroup}>
                      <div className={styles.radioField}>
                        <Field
                          id="manual"
                          type="radio"
                          name="assignModule"
                          value="manual"
                          className={styles.radioInput}
                        />
                        <label htmlFor="manual" className={styles.radioLabel}>
                          Select Manually
                        </label>
                      </div>

                      <div className={styles.radioField}>
                        <Field
                          id="admin"
                          className={styles.radioInput}
                          type="radio"
                          name="assignModule"
                          value="admin"
                          onClick={(val) => {
                            setFieldValue(
                              "functionalRoles",
                              functionalRoles.map((role) => role.id)
                            );
                          }}
                        />
                        <label htmlFor="admin" className={styles.radioLabel}>
                          Make an admin
                          <br /> (gives access to all modules)
                        </label>
                      </div>
                    </div> */}
                  </div>

                  <fieldset
                    disabled={values.assignModule === "admin"}
                    className={styles.checkboxaligngrid}
                  >
                    {functionalRoles?.map((role) => (
                      <CheckBox name="functionalRoles" value={role.id} label={role.name}/>
                    ))}
                  </fieldset>
                  <ErrorMessage name ="functionalRoles">
                    {(msg)=><ErrorLabel text={msg} />}
                  </ErrorMessage>
                </fieldset>

                <div>
                  <hr className={styles.separator} />
                  <ButtonWrapper>
                    <BackButton
                      className={styles.backButton}
                      style={styles.submit}
                      title={"Back"}
                      closePopup={close}
                      // onClose={close}
                    />
                    <Submit
                     
                      title={"Submit"}
                      isSubmitting={isSubmit}
                      //disabled={!isValid || !dirty}
                    />
                  </ButtonWrapper>
                </div>
              </Form>
            )}
          </Formik>
        </Sidebar>
      )}
      {/* {isAlert && (
                  
                    <Alert
                      image={message.image}
                      message={message.message}
                      onClose={setIsAlert}
                    />
                 
                )} */}
    </div>
  );
};

export default NewUser;