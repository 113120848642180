import React, { useEffect, useState } from "react";
import { ChildContainer, Container } from "../../components/Container/Containers";
import { Banner } from "../../components/banner/Banners";
import { Breadcrumbs } from "../../components/breadcrumbs/Breadcrumbs";
import IMAGES from "../../assets/images/images";
import { Tabs } from "../../components/tabs/Tabs";
import CustomerViewer from "./customer/list/CustomerViewer.container";
import VFUSerViewer from "./vfUser/list/VFUserViewer.container";
import OnboardingListViewer from "./customer/onboardingRequest/list/onboardingList.container";
import MyRequest from "./customer/myRequest/myRequest";
import CAN from "../../casl/can";
import { Action, Resource } from "../../casl/constants";
import { connect } from "react-redux";
import { UserManagementTab } from "../../utils/constants/userManagementConstant";
const UserManagement = (props) => {
  const [activeTab, setActiveTab] = useState("");
  const breadcrumbsOption = [
    { label: "User Management", link: "" }
  ];

  const tabOptions = [
    {
      key: UserManagementTab.CUSTOMER,
      label: "Customer",
      canAccess: CAN(Action.LIST, Resource.CUSTOMERS),
    },
    {
      key: UserManagementTab.VF_GROUP_USER,
      label: "VF Group User",
      canAccess: CAN(Action.LIST, Resource.RIGHTS) && props?.myRights?.functionalRoles.length==1 &&  props?.myRights?.functionalRoles[0]=="super_admin_internal", 
    },
     {
        key: UserManagementTab.ON_BOARDING_APPROVALS,
        label: "Onboarding Approvals",
        canAccess: CAN(Action.LIST, Resource.CUSTOMER_ONBOARDING_REQUEST) && CAN(Action.APPROVER, Resource.CUSTOMER_ONBOARDING_REQUEST),
      },
    {
      key: UserManagementTab.MY_REQUEST,
      label: "My Requests",
      canAccess: CAN(Action.LIST, Resource.CUSTOMER_ACCESSR_REQUEST), 
    },
  ];

  const getActiveTab = (tab) => {
    setActiveTab(tab);
  };
  const defaultActiveTab = tabOptions.filter(tab => tab.canAccess);
  return (
    <Container>
      <Banner image={IMAGES.banner} text={"User Management"}></Banner>
      {/* <ChildContainer>
        <Breadcrumbs options={breadcrumbsOption} />
      </ChildContainer> */}
      <Tabs option={tabOptions} defaultActiveTab={defaultActiveTab[0].key} getActiveTab={getActiveTab} />
      <ChildContainer>
        {
          activeTab === UserManagementTab.CUSTOMER  ?
            <CustomerViewer /> :
            activeTab === UserManagementTab.VF_GROUP_USER ?
              <VFUSerViewer /> :
              activeTab === UserManagementTab.ON_BOARDING_APPROVALS ?
                <OnboardingListViewer /> :
                activeTab === UserManagementTab.MY_REQUEST ?
                  <MyRequest /> :
                  <></>
        }
      </ChildContainer>
    </Container>
  );
};

//export default UserManagement;



const mapStateToProps = (state) => {
  
    return { 
     myRights :state.myRights.myRights
    } 
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
    
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
