import React, { useEffect, useState } from "react";
import { ChildContainer, Container } from "../../../../components/Container/Containers";
import { Banner } from "../../../../components/banner/Banners";
import { Breadcrumbs } from "../../../../components/breadcrumbs/Breadcrumbs";
import IMAGES from "../../../../assets/images/images";
import { SubTabs } from "../../../../components/tabs/subtabs";
import AccountApprovalViewer from "./accountAccessApproval/list/accountApprovalListViewer";
import OnboardingRequestListViewer from "./onboardingRequest/list/onboardingRequestList.container";
import AccountRequestViewer from "./accountAccessRequest/list/accountRequestListViewer";
import styles from "./myRequest.module.css";
const MyRequest = () => {
  const [activeTab, setActiveTab] = useState("");

  const tabOptions = {
    ON_BOARDING_APPROVALS: "Onboarding Requests",
    ACCOUNT_ACCESS_REQUEST: "Account Access Requests",
    ACCOUNT_ACCESS_APPROVAL: "Account Access Approvals",
  }

  const tabOption = [tabOptions.ON_BOARDING_APPROVALS, tabOptions.ACCOUNT_ACCESS_REQUEST, tabOptions.ACCOUNT_ACCESS_APPROVAL];
    const getActiveTab = (tab) => {
    setActiveTab(tab);
  }

  return (
    <>
     <SubTabs option={tabOption} defaultActiveTab={tabOptions.ON_BOARDING_APPROVALS} getActiveTab={getActiveTab} />
      <div className={styles.containerinner}>
        {
          activeTab===tabOptions.ON_BOARDING_APPROVALS?
           <OnboardingRequestListViewer/>:
          activeTab===tabOptions.ACCOUNT_ACCESS_REQUEST?
          <AccountRequestViewer/>:
          activeTab===tabOptions.ACCOUNT_ACCESS_APPROVAL?
          <AccountApprovalViewer/>:

         <></>
        }
   

    </div>
    </>
  )

};

export default MyRequest;


