import React, { useState,useEffect } from 'react'
import { Breadcrumbs } from '../../../../../components/breadcrumbs/Breadcrumbs';
import { Banner } from '../../../../../components/banner/Banners';
import IMAGES from '../../../../../assets/images/images';
import { useParams } from 'react-router-dom';
import { ChildContainer, Container } from '../../../../../components/Container/Containers';
import styles from './onboardingDetail.module.css';
import { useNavigate } from 'react-router-dom';
import  AddComment  from '../addComment/addComment.container';
import SectionGuard from '../../../../../hoc/guards/SectionGuard';
import { Action, Resource } from '../../../../../casl/constants';
import CAN from '../../../../../casl/can';

const OnboargingDetailList = ({ data,onFileDownload,loading,...props }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleContent = () => {
      setIsOpen(!isOpen);
    };
    
  const navigate = useNavigate();

  const back = (requestId) => {
    navigate(`/user-management`);
  }
console.log("data",data);

    return (
     <div>
         <div className={styles.back}>
         <div>  <span><img className={styles.backbutton} src={IMAGES.backarrow} onClick={()=>back()} /></span><span className={styles.id}>Airtel</span><span className={styles.alert}>Respond by 4 Sept, or the request will be escalated.</span> </div>
        <div className={styles.tadiglabel}>TADIG ID:<span className={styles.tadigvalue}> 348567</span></div>
      </div>
      <div className={styles.container}>
      <div className={styles.requestDetail}>Customer Details</div>
      <div className={styles.listContent}>
             <div className={styles.list}>
                <div className={styles.label}>Customer Primary Contact</div>
                <div className={styles.value}>John Dave</div>
                <div className={styles.labele}> {data?.requesDetails?.customerType?.value}</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Account Manager Contact</div>
                <div className={styles.value}>IPX Service</div>
                <div className={styles.labele}> {data?.requesDetails?.acContact?.value}</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Product Type</div>
                <div className={styles.value}> IPX Service</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Submitted On</div>
                <div className={styles.value}> 2847569</div>
             </div>
             </div>
             <div className={styles.label}>Additional Information</div>
             <div className={styles.value}> useEffect call and refer to those specific props inside useEff</div>
     </div>
     </div>
    )
  };

  const OnboargingDetail = ({ data,onFileDownload,loading,...props }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    

    return (
     <div>
      
      <div className={styles.container}>
      <div className={styles.requestDetail}>Request Details</div>
      <div className={styles.listContent}>
             <div className={styles.list}>
                <div className={styles.label}>Customer Primary Contact</div>
                <div className={styles.value}>John Dave</div>
                <div className={styles.labele}> {data?.requesDetails?.customerType?.value}</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Account Manager Contact</div>
                <div className={styles.value}>IPX Service</div>
                <div className={styles.labele}> {data?.requesDetails?.acContact?.value}</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Product Type</div>
                <div className={styles.value}> IPX Service</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Submitted On</div>
                <div className={styles.value}>  9375735</div>
             </div>
             </div>
     </div>
     </div>
    )
  };
  const ApprovalContainer = ({ data,onFileDownload,loading,...props }) => {
   
    return (
      <div className={styles.containerapproval}>
      <div className={styles.actionContent}>
            
        <div className={styles.feedbacaction}>
          <div className={styles.feedbackbtn}>
          <SectionGuard canAccess={CAN(Action.CREATE_COMMENT, Resource.CUSTOMER_ONBOARDING_REQUEST)}>
          <AddComment source={'incDetail'}  />
          </SectionGuard>
           <AddComment source="Reject"/>
           <AddComment source="Approve"/>
          </div>
        </div>
             </div>
     </div>
    )
  };

  const CommentDetail = ({data, onFileDownload}) => {
   
    return (
     <div  className={styles.comment} >
     No Data Found
     </div>
    );
  };


const OnboardingDetail = (props)=> {

  let { requestId } = useParams();
  useEffect(() => {
    props.getRequestDetails(requestId);
    window.scrollTo(0,0);
  }, [requestId]);
//console.log("props",props);

  return (
    <Container>
      <Banner image={IMAGES.banner} text={"Onboarding Approvals"}></Banner>
      <ChildContainer>
       {/* {props.requestDetails.map((item) => (
        <CommercialMlist data={item}/>
        ))} */}
          <OnboargingDetailList data={props.requestDetails}/>
          <OnboargingDetail/>
        <ApprovalContainer/>
                 <div  className={styles.initaltext}>Comment History</div>
                   
           
        <CommentDetail/>
      </ChildContainer>
    </Container>
  )
};

export default OnboardingDetail;