import ServiceRequestViewer from "./myserviceRequestViewer";
import { getServiceList } from "../../../../redux/actions/incidentManagement/serviceList.action";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    serviceList: state.serviceList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getServiceList: (next=null)=>{
      return dispatch(getServiceList(next))
  },

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequestViewer);
