import { FormFieldLabel as Label, FormErrorLabel as ErrorLabel, FormFieldLabel } from "../../../../components/label/Labels";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useField } from "formik";
import Asterisk from "../../../../components/asterisk/Asterisk";
import { FormTextArea, TextInput } from "../../../../components/input/inputs";
import DropDown from "../../../../components/dropDown/DropDown";
import FileAttachment from "../../../../components/fileAttachment/fileAttachment.container";
import styles from "./controllers.module.css";
import { Popover, ArrowContainer } from 'react-tiny-popover'
import React, { useId, useEffect, useState } from "react";
import IMAGES from "../../../../assets/images/images";
import {getLabelFromValue} from "../../../../utils/arrayUtils";
import attachment from "../../../../assets/images/attachmentlogo.png";
import moment from 'moment';
import { ADD_TICKET_ACTION } from "../../../../utils/constants/incidentConstants";


export const Wrapper = ({ children }) => {
    return <div className={styles.wrapperContainer}>{children}</div>;
};

export const FormTitle=({title})=>{
    return(
        <div className={styles.formTitle}>{title}</div>
    )
}

const Tooltip = ({ text }) => {
    let [isPopoverOpen, setIsPopoverOpen] = useState(false);
    return (
        <Popover
            isOpen={isPopoverOpen}
            positions={['top']} // preferred positions by priority
            containerClassName={styles.popoverContainer}
            content={({ position, childRect, popoverRect }) => (
                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'#f5f3f3'}
                    arrowSize={10}
                    className='popover-arrow-container'
                    arrowClassName='popover-arrow'
                >
                    <div
                        className={styles.tooltipBody}
                    >
                        {text}
                    </div>
                </ArrowContainer>
            )}


        >
            <img src={IMAGES.infoCircle}
                onMouseLeave={() => setIsPopoverOpen((isPopoverOpen = false))}
                onMouseOver={() => setIsPopoverOpen((isPopoverOpen = true))}
            />

        </Popover>
    );
}

export const RadioContainer = ({ children, sectionTitle }) => {
    return (
        <div className={styles.radioContainer}>
            <div className={styles.radioSectionLabel}>{sectionTitle}</div>
            {children}
        </div>
    )
}

export const RadioField = (props) => {
    return (
                    <label className={styles.redioField}>
                        <Field type="radio" name={props.name} value={props.value} onChange={props.onChange} />
                        <span className={styles.radioLabel}>{props.label}</span>
                        {
                            props.showTooltip ?
                                <Tooltip text={props.tooltipText} />
                                : <></>
                        }
        </label>
    )
}
let failuerTypeDropdownDropObj = {
    width: "300px"
}
export const FailureSelectField = ({ label, options, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error ? true : false;
    return (
        <>
            <div className={styles.FailuerTypeDropdownLabel}>{label}</div>
            {
                !props.isPreviewMode ? <>
                    <div className={styles.FailuerTypeDropdown}>
                        <DropDown
                            options={options}
                            onChange={({ label, value }) => {
                                props.onChange(value);
                                helpers.setValue(value);
                            }}
                            value={
                                ((options?.length > 0) && field.value)
                                    ? options.find((option) => option.value === field.value)
                                    : ""
                            }
                            hasError={hasError}
                            isDisabled={props.isDisabled}
                            customStyles={failuerTypeDropdownDropObj}
                            placeholder={props.placeholder}
                        />
                    </div>
                    {hasError ? <ErrorLabel text={meta.error} /> : null}
                </> : <div className={styles.previewFailureSelectText}>{getLabelFromValue(options,field?.value)}</div>
            }
        </>
    );
};

export const HRLine = () => {
    return (
        <div className={styles.hrLine}></div>
    )
}

export const TextField = ({ disabled = false, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error ? true : false;
    const inputStyle = hasError
        ? `${styles.input} ${styles.inputError}`
        : styles.input;

    return (
        <div className={props.isExpandedField ? `${styles.Field} ${styles.expandedField}` : `${styles.Field}`}>
            <div>
                <Label
                    text={props.label}
                    style={styles.lable}
                />
                {props.isMandatory ? <Asterisk /> : <></>}
                {
                    props.showTooltip ?
                        <>
                            &nbsp;&nbsp;
                            <Tooltip text={props.tooltipText} />
                        </>
                        : <></>
                }
            </div>
            {
                !props.isPreviewMode?
                <>
                    <TextInput
                        name={props.name}
                        placeholder={props.placeholder}
                        type={props.type}
                        disabled={disabled}
                        {...field} style={inputStyle}
                    />
                    <span className={styles.textLimitInfo}>{props.textLimit}</span>
                    {hasError ? <ErrorLabel text={meta.error} /> : null}
                        
                </>:
                <div className={styles.previewText}>
                    {
                        props.type=="datetime-local" && field?.value?
                            moment(field?.value).format('DD MMM YYYY HH:mm'):
                        field?.value?
                            field?.value:
                            "---"
                    }</div>
            }
            
            
        </div>
    )
}

export const TextArea = ({ ...props }) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error ? true : false;
    const inputStyle = hasError
        ? `${styles.input} ${styles.inputError}`
        : styles.input;
    return (
        <div className={props.isExpandedField ? `${styles.Field} ${styles.expandedField}` : `${styles.Field}`}>
            <div>
                <Label
                    htmlFor={props.htmlFor}
                    text={props.label}
                    style={styles.lable}
                />
                {props.isMandatory ? <Asterisk /> : <></>}
                {
                    props.showTooltip ?
                        <>
                            &nbsp;&nbsp;
                            <Tooltip text={props.tooltipText} />
                        </>
                        : <></>
                }
            </div>
            {
                 !props.isPreviewMode?
                 <>
                   <FormTextArea 
                    {...field} 
                    placeholder={props.placeholder} 
                    style={inputStyle} />
                    <span className={styles.textLimitInfo}>{props.textLimit}</span>
                    {hasError ? <ErrorLabel text={meta.error} /> : null}
                 </>:
                <div className={styles.previewText}>{field?.value?field?.value:"---"}
                </div>
            }
          
        </div>
    );
};

let SelectFieldObj = {
    width: "auto"
}

export const SelectField = ({ options, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error ? true : false;
    return (
        <div className={props.isExpandedField ? `${styles.Field} ${styles.expandedField}` : `${styles.Field}`}>
            <div>
                <Label
                    text={props.label}
                    style={styles.lable}
                />
                {props.isMandatory ? <Asterisk /> : <></>}
                {
                    props.showTooltip ?
                        <>
                            &nbsp;&nbsp;
                            <Tooltip text={props.tooltipText} />
                        </>
                        : <></>
                }
            </div>
            {
                 !props.isPreviewMode?
                 <>
                    <DropDown
                        options={options}
                        onChange={({ label, value }) => {
                            props.onChange(value);
                            helpers.setValue(value);
                        }}
                        value={
                            ((options?.length > 0) && field.value)
                                ? options.find((option) => option.value === field.value)
                                : ""
                        }
                        hasError={hasError}
                        isDisabled={props.isDisabled}
                        customStyles={SelectFieldObj}
                        placeholder={props.placeholder}
                    />
                    {hasError ? <ErrorLabel text={meta.error} /> : null}
                 </>
                 :<div className={styles.previewText}>{field?.value?getLabelFromValue(options,field?.value):"---"}</div>
            }
        </div>
    );
};

export const ImageUpload = ({
    setFieldValue,
    value,
    label = "Supporting Attachments",
    onFileDownload,
    actionType,
    ...props
  }) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error ? true : false;
    const downloadFile = (file, index,onDownload) => {   
        onDownload(file)
      }
    return (
        <>
        {
            !props.isPreviewMode?
                <div className={`${styles.Field} ${styles.expandedField}`}>
                    <FileAttachment name={props.name} value={value} setFieldValue={setFieldValue} />
                    {hasError ? <ErrorLabel text={meta.error} /> : null}
                </div>:
            field.value?.length>0?
                <div className={`${styles.ImagePreviewField} ${styles.expandedField}`}>
                    {
                        field.value.map((file, index) => {
                            return (
                                <div className={styles.thumb} key={index}>
                                    <div className={styles.thumbInner}>
                                        <div className={styles.attachment}>
                                            <img className={styles.preview}
                                                src={attachment}
                                            //onLoad={() => { URL.revokeObjectURL(file.preview) }} alt="imageIcon"
                                            />
                                        </div>
                                        <div className={styles.fileName}>{file.originalFileName}</div>
                                        {actionType===ADD_TICKET_ACTION.INCIDENT_DETAILS&&(
                                            <img className={styles.attachlogo} src={IMAGES.downloadfile} onClick={() => downloadFile(file, index,onFileDownload)} />
                                        )}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            :<></>
        }
      </>
    );
  };