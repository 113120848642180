import { Sidebar } from "../../../../../components/sidebar/Sidebar";
import styles from "../addServiceRequest.module.css";

import React, { useEffect, useState } from "react";
import { Formik, Field, Form, useField } from "formik";
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
} from "../../../../../components/label/Labels";
import { FormTextArea, TextInput } from "../../../../../components/input/inputs";
import * as Yup from "yup";
import { Submit, BackButton } from "../../../../../components/button/buttons";
import FileAttachment from "../../../../../components/fileAttachment/fileAttachment.container";
import { DetailContainer } from "../../../../../components/comment/comment";
import Asterisk from "../../../../../components/asterisk/Asterisk";
import IMAGES from "../../../../../assets/images/images";
import * as DOMPurify from "dompurify";
import DropDown from "../../../../../components/dropDown/DropDown";
import Tooltip from "../../../../../components/toolTip/toolTip";
import { connect } from "react-redux";
import { openAlertPopup } from "../../../../../redux/actions/Alert/alert.action";
import { SubmitService } from "../../../../../redux/actions/incidentManagement/raiseServiceRequest.action";
import { ServiceImpacted, SingleMultiple } from "../../../../../utils/constants/incidentConstants";
import { getServiceList } from "../../../../../redux/actions/incidentManagement/serviceList.action";
const IPX = (props) => {
  const [isSubmit, setIsSubmit] = useState(false);

  const FieldWrapper = ({ children }) => {
    return <div className={styles.fieldWrapper}>{children}</div>;
  };
  const ButtonWrapper = ({ children }) => {
    return <div className={styles.buttonContainer}>{children}</div>;
  };

  const TextField = ({ label, placeholder, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error ? true : false;
    const inputStyle = hasError
      ? { ...styles.inputError, width: "250px"}
      : { width: "250px" };

    return (
      <FieldWrapper>
        <div>
          <Label text={label} style={styles.labelcompLevel}/>
          {props.name === "issue" ?<Asterisk />:<></>}<span className={styles.circlered}>
           {props.tooltiprquired == "true"? <span className={styles.tooltip} ><Tooltip text={props.tooltiptxt}/></span>:<></>}
            </span>
        </div>
        <TextInput
          {...field}
          {...props}
          placeholder={placeholder}
          style={styles.additionalStyle}
          
        />
        {hasError ? <ErrorLabel text={meta.error} /> : null}
      </FieldWrapper>
    );
  };

  //   const FieldWrapper = ({ children }) => {
  //     return <div className={styles.fieldContainer}>{children}</div>;
  //   };

  const SelectField = ({ title, options, ...props }) => {
    let _options = options.map((item) => ({
      value: item.value,
      label: item.label,
    }));
    const [field, meta, helpers] = useField(props);


    const hasError = meta.touched && meta.error ? true : false;
    return (
      <FieldWrapper>
        <div>
          <Label text={title} style={styles.labelcompLevel}/><span className={styles.circlered}><span className={styles.tooltip}>{props.name === "ServiceImpacted"?<Asterisk/>:<></>}<Tooltip text={props.tooltiptxt}/></span></span>
        </div>
        <DropDown
          options={_options}
          customStyles={props.customStyles}
          onChange={({ label, value }) => {
            helpers.setValue(value);
          }}
          value={
            _options && field.value
              ? _options.find((option) => option.value === field.value)
              : ""
          }
          hasError={hasError}
          isDisabled={props.isDisabled}
        />
        {hasError ? <ErrorLabel text={meta.error} /> : null}
      </FieldWrapper>
    );
  };

  const TextArea = ({ label, optional = false, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error ? true : false;
    const inputStyle = hasError
      ? `${styles.input} ${styles.inputError}`
      : styles.input;
    return (
      <FieldWrapper>
        <div className={styles.labelcomment}>
          <Label text={label} optional={optional} style={styles.labelcompLevel}/>
          <Asterisk /><span className={styles.circlered}>
           {props.tooltiprquired == "true"? <span className={styles.tooltip} ><Tooltip text={props.tooltiptxt}/></span>:<></>}
            </span>
        </div>
        <FormTextArea {...field} {...props} style={styles.additionalStyle} />
        {hasError ? <ErrorLabel text={meta.error} /> : null}
      </FieldWrapper>
    );
  };

  const ImageUpload = ({
    setFieldValue,
    value,
    label = "Supporting Attachments",
    ...props
  }) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error ? true : false;
    return (
      <FieldWrapper>
          <div className={styles.labelcomment}>
        <Label text={label} optional={false}style={styles.labelcompLevel} /><span className={styles.circlered}>
           {props.tooltiprquired == "true"? <span className={styles.tooltip} ><Tooltip text={props.tooltiptxt}/></span>:<></>}
            </span> </div>
        <FileAttachment
          name={props.name}
          value={value}
          setFieldValue={setFieldValue}
        />
        {hasError ? <ErrorLabel text={meta.error} /> : null}
      </FieldWrapper>
    );
  };
  let _initialValues = {
    issue: "",
    problemStatement: "",
    ServiceImpacted: "",
    SingleMultiple: "",
    IMSI: "",
    files: [],
  };

  const validationSchema = Yup.object({
    issue: Yup.string().max(100, "Title must be at most 100 characters").required("Required"),
    problemStatement: Yup.string().max(1000, "Problem statement must be at most 1000 characters").required("Required"),
    ServiceImpacted: Yup.string().required("Required"),
    SingleMultiple: Yup.string(),
    IMSI: Yup.string(),
    files: Yup.array().max(3),
  });


  const obj = {
    width: "270px",
  };

  return (
    <Formik
      initialValues={_initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange
      onSubmit={async (values, { resetForm }) => {
        setIsSubmit(true);
        let obj = {
          serviceRequestType: props.selectedForm,
          servicesImpacted: values.ServiceImpacted,
          singleOrmultipleMsisdn: values.SingleMultiple,
          serviceRequestDetails:{
               title: values.issue,
               problemStatement: values.problemStatement,
               IMSIDetails: values.IMSI,
               attachement: values.files,
          }
      };
      
        props.addService(obj).then((result) => {
            if (result.status) {
              props.openAlertPopup({
                message: "Service request submitted successfully!",
                image: IMAGES.success,
              });
              setIsSubmit(false);
              resetForm();
              props.onClose();
              props.getServiceList();
            } else {
              let error_msg = result?.error?.message ?? "Something went wrong.";

              props.openAlertPopup({
                message: error_msg,
                image: IMAGES.error,
                isFormOpen:true
              });
              setIsSubmit(false);
            //  props.onClose();
            }
          })
          .catch((error) => {
            props.openAlertPopup({
              message: "Something went wrong.",
              image: IMAGES.error,
              isFormOpen:true
            });
            setIsSubmit(false);
         //   props.onClose();
          });
      }}
    >
      {({ values,dirty, setFieldValue, resetForm }) => (
        <>
        <Form 
        className={styles.form}
         id="ipx_service_request"
        >   
            {props.FormType !== "IPX" ? <hr className={styles.separatortop} />:<></>}
            <div className={styles.note}>Please Provide The Details</div>
          <TextField
            placeholder="Enter here"
            label="Title"
            name="issue"
            type="text"
            tooltiprquired="true"
            tooltiptxt="Title"
          />
          <div className={styles.commentValidation}>100 characters</div>
          <div className={styles.dropdowngrid}>
            <div className={`${styles.dropdownwidth} ${styles.dropdownwidths}`} >
              <SelectField
                title={"Service Impacted"}
                name="ServiceImpacted"
                options={ServiceImpacted}     
                customStyles={styles.obj}
                 tooltiptxt="Service Impacted"
                //  onChange={handleServiceTypeChange}
              />
            </div>
            <div className={styles.dropdownwidth}>
              <SelectField
                title={"Single or Multiple MSISDN"}
                name="SingleMultiple"
                options={SingleMultiple}
                customStyles={styles.obj}
                 tooltiptxt="Single or Multiple MSISDN"
                //  onChange={handleServiceTypeChange}
              />
            </div>
          </div>
          <div className={styles.textarea}>
            <TextArea
              label={"Problem Statement"}
              id="ProblemStatement"
              name="problemStatement"
              placeholder="Enter the description"
              tooltiprquired="true"
              tooltiptxt="Problem Statement"
            />
          </div>
          <div className={styles.commentValidation}>1000 characters</div>
          <div className={styles.maintextbox}>
          <div className={styles.textbox}>
            <TextField
              placeholder="Enter IMSI number"
              label="IMSI"
              name="IMSI"
              type="text"
              tooltiptxt="IMSI"
               tooltiprquired="true"
            />
          </div>
          <div className={styles.textbox}></div>
          </div>
          <ImageUpload
            name="files"
            value={values.files}
            setFieldValue={setFieldValue}
            optional={false}
            tooltiptxt="Supporting Attachments"
            tooltiprquired="true"
          />       
        </Form>
         <div className={styles.footer}>
         <hr className={styles.separator} />
         <ButtonWrapper>
           <BackButton
             className={styles.backButton}
             style={styles.submitt}
             title={"Cancel"}
             closePopup={props.onClose}
           />
           <Submit
             id="saveButton"
             className={styles.saveButton}
             style={styles.submitivc}
             title={"Submit"}
             disabled={!dirty}
             form="ipx_service_request"
             isSubmitting={isSubmit}
           />
         </ButtonWrapper>
         </div>
        </>
      )}
    </Formik>
  );
};


const mapStateToProps = (state) => {
  return {
   
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openAlertPopup: (payload) => {
        return dispatch(openAlertPopup(payload))
      },
      addService: (payload) => {
        return dispatch(SubmitService(payload))
      },
      getServiceList: (next=null)=>{
        return dispatch(getServiceList(next))
    },
  

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IPX);


