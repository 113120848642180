import { Link, useParams, useSearchParams } from "react-router-dom";
import IMAGES from "../../../../../../assets/images/images";
import {
  ChildContainer,
  Container,
} from "../../../../../../components/Container/Containers";
import { FormFieldLabel as Label, FormErrorLabel as ErrorLabel, FormFieldLabel } from "../../../../../../components/label/Labels";
import { Search } from "../../../../../../components/search/search";
import styles from "./onboardingRequestListViewer.module.css";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { formatSecondsToDate } from "../../../../../../utils/dateTime";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../../../../../components/loader/Loader";
import { EmptyContainer } from "../../../../../dashboard/graphs/component/component";
import { useNavigate } from "react-router-dom";
import DropDown from "../../../../../../components/dropDown/DropDown";
import { useField } from "formik";
import {getLabelFromValue} from "../../../../../../utils/arrayUtils";

const OnboardingRequestList = ({data}) => {

    const navigate = useNavigate();
    const gotoDetail = (requestId) => {
      navigate(`/OnboardingRequestDetail/${requestId}`);
    }

    return (
  <> 
       <div className={styles.container}>
                
         <div className={styles.head}>
              <div className={styles.subhead}>
                    <div className={styles.orgname} onClick={()=>gotoDetail(data.id)}>REQ-20240927-5</div>
                    <div className={styles.status}>Pending</div>
              </div>
              <div> <img onClick={()=>gotoDetail(data.id)} className={styles.gotologo} src={IMAGES.chevronredright} alt="" /></div>
         </div>
         <div className={styles.listContent}>
             <div className={styles.list}>
                <div className={styles.label}>Organisation Legal name</div>
                <div className={styles.value}>{data?.serviceType?.label}</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Service Type</div>
                <div className={styles.value}>{data?.tadigId}</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Request Assigned To</div>
                <div className={styles.value}>{data?.modifiedBy?.acName}</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>TADIG Code</div>
                <div className={styles.value}>{data?.createdAt}</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Requested On</div>
                <div className={styles.value}>{data?.createdAt}</div>
             </div>
             </div>           
       </div>
  </>
    );
  };
  

const OnboardingRequestListViewer = (props) => {

    const FieldWrapper = ({ children }) => {
        return <div className={styles.fieldWrapper}>{children}</div>;
      };

    const SelectField = ({ title, options, ...props }) => {
        // let _options = options.map((item) => ({
        //   value: item.value,
        //   label: item.label,
        // }));
       // const [field, meta, helpers] = useField(props);
    
    
        //const hasError = meta.touched && meta.error ? true : false;
        const obj = {
            width: "249px",
        }
        return (

          <FieldWrapper>
            {/* <div>
              <Label text={title} style={styles.labelcompLevel}/>
            </div> */}
            <DropDown
              //options={_options}
            //   customStyles={props.customStyles}
            //   onChange={({ label, value }) => {
            //     helpers.setValue(value);
            //   }}
            //   value={
            //     _options && field.value
            //       ? _options.find((option) => option.value === field.value)
            //       : ""
            //   }
            //   hasError={hasError}
            customStyles ={obj}
            //   isDisabled={props.isDisabled}
            />
            {/* {hasError ? <ErrorLabel text={meta.error} /> : null} */}
          </FieldWrapper>
        );
      };

      useEffect(() => {
        props.getRequestList();
      }, []);

  return (
<ChildContainer> 
    <div className={styles.headnote}><img className={styles.infocircle}src={IMAGES.infoCircle}/>Onboarding requests description</div>
    <div className={styles.searchhead}>
           <div className={styles.search}>
            {/* <div>Search</div> */}
             <Search 
                //  filterData={filterData}
                //  getSearchKeyValue={getSearchKeyValue}
                //  placeholder="Search"
                //  onKeyDown={onKeyDownHandler}
              />
            </div>
            <div className={styles.statussearch}>
              <SelectField
              title="Status" 
              />
            </div>
    </div>
    {props?.requestList?.map((item) => (
    <OnboardingRequestList  data={item}/>
    ))}
</ChildContainer>
  );
};

export default OnboardingRequestListViewer;
