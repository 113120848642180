
export const Incident_Service_Type_Values = {
    IPX_Service: "ipx_service",
    IVC_Service: "ivc_service"
}

export const Incident_Issue_Type_Values = {
    IVC_Voice_Issues: "ivc_voice_issue",
    IPX_Roaming_Issues: "ipx_roaming_issue",
    IPX_IP_Connectivity: "ipx_ip_connectivity_issue",
    IVC_IP_Connectivity: "ivc_ip_connectivity_issue"
}

export const IncidentServiceTypes = [
    {
        label: "IPX Services",
        value: Incident_Service_Type_Values.IPX_Service,
        tooltipText: "This is IPX Service"
    },
    {
        label: "International Voice Services",
        value: Incident_Service_Type_Values.IVC_Service,
        tooltipText: "This is IVC Service"
    }
]

export const IPXIssueTypes = [
    {
        label: "Roaming Service",
        value: Incident_Issue_Type_Values.IPX_Roaming_Issues,
        tooltipText: "This is Roaming Issues"
    },
    {
        label: "IP Connection to Vodafone POP",
        value: Incident_Issue_Type_Values.IPX_IP_Connectivity,
        tooltipText: "This is IP Connectivity"
    }
]


export const IVCIssueTypes = [
    {
        label: "Voice Service",
        value: Incident_Issue_Type_Values.IVC_Voice_Issues,
        tooltipText: "This is Voice Issue"
    },
    {
        label: "IP Connection to Vodafone POP",
        value: Incident_Issue_Type_Values.IVC_IP_Connectivity,
        tooltipText: "This is IP Connectivity"
    }
]

export const IVC_VOICE_FAILURE_OPTIONS_VALUES = {
    Call_Failure: "call_failure",
    KPI_Degradation: "kpi_degradation",
    Traffic_Profile: "traffic_profile",
    CLI_Delivery: "cli_delivery",
    DTMF_Failure: "dtmf_failure",
    Audio_Quality: "audio_quality",
    ITFS_Issues: "itfs_issues",
    LNS_Issues: "lns_issues",
    UIFN_Issues: "uifn_issues",
    SIP_Trunk_Connectivity_Issues: "sip_trunk_connectivity_issues",
    Roaming_Voice_Issues: "roaming_voice_issues",
}

export const IVC_VOICE_FAILURE_OPTIONS = [
    { label: "Call Failure", value: IVC_VOICE_FAILURE_OPTIONS_VALUES.Call_Failure },
    { label: "KPI Degradation", value: IVC_VOICE_FAILURE_OPTIONS_VALUES.KPI_Degradation },
    { label: "Traffic Profile", value: IVC_VOICE_FAILURE_OPTIONS_VALUES.Traffic_Profile },
    { label: "CLI Delivery", value: IVC_VOICE_FAILURE_OPTIONS_VALUES.CLI_Delivery },
    { label: "DTMF Failure", value: IVC_VOICE_FAILURE_OPTIONS_VALUES.DTMF_Failure },
    { label: "Audio Quality", value: IVC_VOICE_FAILURE_OPTIONS_VALUES.Audio_Quality },
    { label: "ITFS Issues", value: IVC_VOICE_FAILURE_OPTIONS_VALUES.ITFS_Issues },
    { label: "LNS Issues", value: IVC_VOICE_FAILURE_OPTIONS_VALUES.LNS_Issues },
    { label: "UIFN Issues", value: IVC_VOICE_FAILURE_OPTIONS_VALUES.UIFN_Issues },
    { label: "SIP Trunk Connectivity Issues", value: IVC_VOICE_FAILURE_OPTIONS_VALUES.SIP_Trunk_Connectivity_Issues },
    { label: "Roaming Voice Issues", value: IVC_VOICE_FAILURE_OPTIONS_VALUES.Roaming_Voice_Issues }
];

export const IPX_SIGNALLING_ISSUE_OPTIONS_VALUES = {
    Sigtran: "sigtran",
    Diameter: "diameter",
    GRX : "grx"
}

export const IPX_SIGNALLING_ISSUE_OPTIONS = [
    { label: "Sigtran", value: IPX_SIGNALLING_ISSUE_OPTIONS_VALUES.Sigtran },
    { label: "Diameter", value: IPX_SIGNALLING_ISSUE_OPTIONS_VALUES.Diameter },
    { label: "GRX", value: IPX_SIGNALLING_ISSUE_OPTIONS_VALUES.GRX }
];

export const IPX_IMPACTED_SUBSCRIBER_OPTIONS_VALUES = {
    Single: "single",
    Multiple: "multiple",
}

export const IPX_IMPACTED_SUBSCRIBER_OPTIONS = [
    { label: "Single", value: IPX_IMPACTED_SUBSCRIBER_OPTIONS_VALUES.Single },
    { label: "Multiple", value: IPX_IMPACTED_SUBSCRIBER_OPTIONS_VALUES.Multiple }
];

export const IP_CONNECTIVITY_FAILURE_OPTIONS_VALUES = {
    BGP_Session_Down: "bgp_session_down",
    Interface_Down: "interface_down",
    Packet_Drop_High_Latency_High_Utilization_Down: "packet_drop_high_latency_high_utilization",
}

export const IP_CONNECTIVITY_FAILURE_OPTIONS = [
    { label: "BGP Session Down", value: IP_CONNECTIVITY_FAILURE_OPTIONS_VALUES.BGP_Session_Down },
    { label: "Interface Down", value: IP_CONNECTIVITY_FAILURE_OPTIONS_VALUES.Interface_Down },
    { label: "Packet Drop/High Latency/High Utilization", value: IP_CONNECTIVITY_FAILURE_OPTIONS_VALUES.Packet_Drop_High_Latency_High_Utilization_Down }
];


export const Priority_Options_VALUES = {
    P1: "p1",
    P2: "p2",
    P3: "p3",
}

export const Priority_Options=[
    {
        label:'P1',
        value:Priority_Options_VALUES.P1,
        info:"P1 when complete service outage for all your voice traffic via Vodafone"
    },
    {
        label:'P2',
        value:Priority_Options_VALUES.P2,
        info:"P2 when partial traffic outage for all your voice traffic via Vodafone"
    },
    {
        label:'P3',
        value:Priority_Options_VALUES.P3,
        info:"P3 for single number/minor failures"
    }
]

export const Yes_No_VALUES={
    Yes:'yes',
    No:'no'
}

export const Yes_No_Options=[
    {
        label:'Yes',
        value:Yes_No_VALUES.Yes,
    },
    {
        label:'No',
        value:Yes_No_VALUES.No,
    }
]

export const IVC_CALL_FAILURE_SUBTYPE_VALUES={
    PDD:'pdd',
    FAX:'fax',
    FAS:'fas',
    Call_Looping:'call_looping',
    Other:'other'
}

export const IVC_CALL_FAILURE_SUBTYPE_OPTIONS=[
    {
        label:'PDD',
        value:IVC_CALL_FAILURE_SUBTYPE_VALUES.PDD,
    },
    {
        label:'FAX',
        value:IVC_CALL_FAILURE_SUBTYPE_VALUES.FAX,
    },
    {
        label:'FAS',
        value:IVC_CALL_FAILURE_SUBTYPE_VALUES.FAS,
    },
    {
        label:'Call Looping',
        value:IVC_CALL_FAILURE_SUBTYPE_VALUES.Call_Looping,
    },
    {
        label:'Other',
        value:IVC_CALL_FAILURE_SUBTYPE_VALUES.Other,
    }
]

//KPI_Degradation
export const IVC_KPI_DEGRADATION_SUBTYPE_VALUES={
    LOW_NER:'low_ner',
    LOW_ASR:'low_asr',
    LOW_ACD:'low_acd',
    LOW_MOS:'low_mos'
}

export const IVC_KPI_DEGRADATION_SUBTYPE_OPTIONS=[
    {
        label:'Low NER',
        value:IVC_KPI_DEGRADATION_SUBTYPE_VALUES.LOW_NER,
    },
    {
        label:'Low ASR',
        value:IVC_KPI_DEGRADATION_SUBTYPE_VALUES.LOW_ASR,
    },
    {
        label:'Low ACD',
        value:IVC_KPI_DEGRADATION_SUBTYPE_VALUES.LOW_ACD,
    },
    {
        label:'Low MOS',
        value:IVC_KPI_DEGRADATION_SUBTYPE_VALUES.LOW_MOS,
    }
]
//DTMF_Failure
export const IVC_DTMFL_FAILURE_METHOD_VALUES={
    Inband:'inband',
    RFC_2833:'RFC_2833',
    Out_Of_Band:'out_of_band'
}

export const IVC_DTMFL_FAILURE_METHOD_OPTIONS=[
    {
        label:'Inband',
        value:IVC_DTMFL_FAILURE_METHOD_VALUES.Inband,
    },
    {
        label:'RFC 2833',
        value:IVC_DTMFL_FAILURE_METHOD_VALUES.RFC_2833,
    },
    {
        label:'Out of Band',
        value:IVC_DTMFL_FAILURE_METHOD_VALUES.Out_Of_Band,
    }
]

//Audio_Quality
export const IVC_AUDIO_QUALITY_VALUES={
    One_Way_Audio:'one_way_audio',
    Distorted_Audio:'distorted_audio',
    Dead_Air:'dead_air'
}

export const IVC_AUDIO_QUALITY_OPTIONS=[
    {
        label:'One Way Audio',
        value:IVC_AUDIO_QUALITY_VALUES.One_Way_Audio,
    },
    {
        label:'Distorted Audio',
        value:IVC_AUDIO_QUALITY_VALUES.Distorted_Audio,
    },
    {
        label:'Dead Air',
        value:IVC_AUDIO_QUALITY_VALUES.Dead_Air,
    }
]

export const MY_INCIDENT_TABS = {
    "MY_INCIDENTS":"My Incidents",
    "MY_SERVICE_REQUEST":"My Service Requests"
 }

 //status
 export const STATUS_ENUM = {
    NEW : "new",
    PENDING : "pending",
    IN_PROGRESS : "in_progress",
    ASSIGNED : "assigned",
    RESOLVED : "resolved",
    CLOSED : "closed",
    NEED_MORE_INFORMATION : "need_more_information",
    CUSTOMER_FEEDBACK_AWAITED:"customer_feedback_awaited"
 }

 // Raise service request
 export const SERVICE_REQUEST_TYPES_VALUES = {
    IPX_Services: "ipx_service",
    International_Voice_Services: "ivc_service",
}
 export const ServiceRequestTypes = [
    { label: "IPX Services", value: "ipx_service" },
    { label: "International Voice Services", value: "ivc_service" }
  ];

export const ServiceImpacted =[
    {label: "Diameter",value: "diameter"},
    {label: "Sigtran",value: "sigtran"},
  ];
  
export const SingleMultiple =[
     {label: "Single",value: "single"},
     {label: "Multiple",value: "multiple"},
 ];
 export const ADD_TICKET_ACTION ={
    NEW_INCIDENT:"new_incident",
    INCIDENT_DETAILS:"incident_details"
 }

 export const COMMENT_SOURCE_TYPE = {
    PROVIDE_INFORMATION:"provide_information",
    NOT_RESOLVED:"not_resolved",
    ACCEPTED:"accepted",
    REGULAR_COMMENT:"incDetail"
 }
