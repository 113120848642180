import { connect } from "react-redux";
import { getRequestList } from "../../../../../redux/actions/userManagement/onboardingRequest.action";
import OnboardingListViewer from "./onboardingListViewer";

const mapStateToProps = (state) => {
  console.log("statecmlist", state);
  
  return {
    requestList : state.onboardingRequest.onboardingList.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { 
     getRequestList: (requestId)=>{
      return dispatch(getRequestList(requestId))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingListViewer);
