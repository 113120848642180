import { BASE_URL, GET, SERVICE_LIST } from "../constants";
import { statusParsing, jsonParsing } from "../utils";
import { getAccessToken } from "../../authActions/authActions";

const obj = [
    {
      "id": "su5reyzXWgu7UoQzRWqM",
      "createdAt": 1727428648,
      "createdBy": {
        "user_id": "mEyWHi3TuQZizc4Z1YlHKXrkqWw2",
        "name": "BICS admin",
        "email": "bics_user1@email.com"
      },
      "requesDetails": {
      
        "acContact": {
          "label": "Account Manager Contact",
          "value": "dinesh@email.com"
        },
        "subon": {
          "label": "subbmited on",
          "value": "2024-09-03 13:50"
        },
        "serviceType": {
          "label": "IPX Service",
          "value": "ipx_service"
        },
        "customerType": {
          "label": "Customer Primary Contact ",
          "value": "john@email.com"
        }
      },
      "requestId": "INC-20240927-26",
      "serviceType": {
        "label": "IPX Service",
        "value": "ipx_service"
      },
      "tadigId": "7788",
      "modifiedAt": 1727436537,
      "modifiedBy": {
        "acName": "Dinesh Kalaskar",
        "account_manager": "Account Manager",
        "email": "dinesh@email.com"
      },
      "status": {
        "label": "New",
        "value": "new"
      }
    },
    {
      "id": "s5reyzXWgu7UoQzRWqM",
      "createdAt": 1727428648,
      "createdBy": {
        "user_id": "mEyWHi3TuQZizc4Z1YlHKXrkqWw2",
        "name": "BICS admin",
        "email": "bics_user1@email.com"
      },
      "issueDetails": {
        "visitedNetworkPLMN": "India_Vodafone",
        "additionalInformation": "",
        "traceRoutes": "looked up one of the more obscure",
        "mobileSubscriberIdentityAParty": "7898789789",
        "problemStatement": "Lorem Sachin",
        "homeNetworkPLMN": "UK Vodafone",
        "destinationPointCodeDetails": "668",
        "title": "Frequent connectivity issues in ipx 27092024-1450",
        "priority": "",
        "IMSIDetails": "99-8989",
        "mobileSubscriberIdentityBParty": "9999999999",
        "destinationIPDetails": "192.168.92.30",
        "callingGlobalTitles": "989898989",
        "issueStartedDateTime": "2024-09-03 13:50",
        "errorReceived": "There are many variations",
        "sourceIPDetails": "192.168.92.1",
        "calledGlobalTitles": "8989898998",
        "tracesTextOrIMSIAttachment": [],
        "tracesTextOrIMSI": "looked up one of",
        "originatingPointCodeDetails": "668"
      },
      "requestId": "INC-20240927-26",
      "serviceType": {
        "label": "IPX Service",
        "value": "ipx_service"
      },
      "tadigId": "2233",
      "modifiedAt": 1727436537,
      "modifiedBy": {
        "acName": "Dinesh Kalaskar",
        "account_manager": "Account Manager",
        "email": "dinesh@email.com"
      },
      "status": {
        "label": "New",
        "value": "new"
      }
    }
  ];


export const fetchrequestDetail = async (requestId) => {

return obj;


//   const token = await getAccessToken();
//   const url = `${BASE_URL}${SERVICE_LIST}/${requestId}`;
//   return fetch(url, {
//       method: GET,
//       headers: {
//         "Content-Type": "application/json",
//         "userauth" : `bearer ${token}`
//       },
//     })
//     .then(statusParsing)
//     .then(jsonParsing);
};
