import {
    BASE_URL, 
    POST,  
    ONBOARDING_COMMENTS,
    GET
  } from "../constants";
  import { statusParsing, jsonParsing } from "../utils";
  import { getAccessToken } from "../../authActions/authActions";
  
  export const addRequest = async (payload) => {
    const token = await getAccessToken();
    const resource = `${BASE_URL}${ONBOARDING_COMMENTS}`;
    return fetch(resource, {
      method: POST,
      headers: {
        "Content-Type": "application/json",
        "userauth": `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    })
      .then(statusParsing)
      .then(jsonParsing);
  };
  

//   export const fetchServiceComments = async (requestId,next) => {

//     const token = await getAccessToken();    
//     let url = `${BASE_URL}${SERVICE_COMMENTS}`;
//     let paramsArray = [
//       { key: "serviceRequestId", value: requestId },
//       { key: "next", value: next }
//   ];
//   let queryString = paramsArray
//         .filter(item => item.value)
//         .map(item => `${item.key}=${encodeURIComponent(item.value)}`)
//         .join('&');
//     if (queryString) {
//         url = `${url}?${queryString}`;
//     }
//     return fetch(url, {
//         method: GET,
//         headers: {
//           "Content-Type": "application/json",
//           "userauth" : `bearer ${token}`
//         },
//       })
//       .then(statusParsing)
//       .then(jsonParsing);
//   };