import { FETCH_REQUEST_LIST } from "../actionTypes";
import { fetchOnboardingList } from "../../../api/userManagement/onboardingList.service";
const requestListRequest = ()=>({type: FETCH_REQUEST_LIST.REQUEST})
const requestListFailure = (error)=>({type: FETCH_REQUEST_LIST.FAILURE, error})
const requestListSuccess = (obj)=>({type: FETCH_REQUEST_LIST.SUCCESS, obj})


export const getRequestList = (next=null)=>{
  //  console.log("sddsfdf" )
    return (dispatch, getState)=>{        
        dispatch(requestListRequest())
        return fetchOnboardingList(next)  
        .then(response => {   
            //   console.log("response", response);
                     
            let obj={
                'data':response,
                'next':response.next,
                "isLoadMore": next?true:false
            }    
            dispatch(requestListSuccess(obj))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(requestListFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}