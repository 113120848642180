import React, { useState,useEffect } from 'react'
import { Breadcrumbs } from '../../../../components/breadcrumbs/Breadcrumbs';
import { Banner } from '../../../../components/banner/Banners';
import IMAGES from '../../../../assets/images/images';
import { useParams } from 'react-router-dom';
import { ChildContainer, Container } from '../../../../components/Container/Containers';
import styles from './serviceRequestDetail.module.css';
import { useNavigate } from 'react-router-dom';
import AddServiceComment from '../addServiceComment/addServiceComment';
import AddComment from '../../addComment/addComment.container';
import moment from 'moment';
import { Loader } from '../../../../components/loader/Loader';
import InfiniteScroll from "react-infinite-scroll-component";
import { formatDate } from '../../../../utils/arrayUtils';
import { STATUS_ENUM } from '../../../../utils/constants/incidentConstants';
import SectionGuard from '../../../../hoc/guards/SectionGuard';
import CAN from '../../../../casl/can';
import { Action, Resource } from '../../../../casl/constants';

const ExpandableText = ({ text = '' }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const previewText = text?.slice(0,100);
  const showViewMore = text?.length>100;

  return (
      <span>
        {isExpanded ? text : `${previewText}${showViewMore?"...":""}`}
        {showViewMore&&(
           <span className={styles.viewMore} onClick={handleToggle}>
           {isExpanded ? ' View Less' : ' View More'}
         </span>
        )}
     </span>
  );
};

const ViewLessData = ({data}) => {
  return (
    <>
      <div className={styles.titleContainer}>
      {/* <div className={styles.detailmain}> */}
      <div className={`${styles.DetailsDiv} ${styles.sepration}`}>Service Type <span className={styles.data}> {data.serviceRequestType?.label}</span></div>
      <div className={`${styles.DetailsDiv} ${styles.sepration} ${styles.borderPadding}`}>Service Impacted <span className={styles.data}> {data.servicesImpacted?.label}</span></div>
      <div className={`${styles.DetailsDiv} ${styles.borderPadding} ${styles.sepration}`}>Single or Multiple MSISDN <span className={styles.data}> {data?.singleOrmultipleMsisdn?.label}</span></div>
      <div className={`${styles.DetailsDiv} ${styles.sepration} ${styles.borderPadding}`}>IMSI <span className={styles.data}> {data.serviceRequestDetails?.IMSIDetails}</span></div>
      <div className={`${styles.DetailsDiv} ${styles.borderPadding} ${styles.borderPadding}`}>Submitted On <span className={styles.data}> {formatDate(data?.createdAt)}</span></div>
      {/* </div> */}
      </div>
    </>
  );
};
const ViewMoreData = ({ data,onFileDownload }) => {
const text = data?.serviceRequestDetails?.problemStatement;
  const downloadFile = (file, index,onDownload) => {   
    onDownload(file)
  }
  return (
      <>   
       <div className={styles.ViewmoreDiv}><span>Problem Statement</span><span className={styles.data}> 
          <ExpandableText text={text}/></span></div>
          {data?.serviceRequestDetails?.attachement?.length>0 && (
            <span className={styles.Divattachment}>Attachments</span>
          )}
          <div className={styles.ViewmoreDivattachment}>
          {/* {data?.serviceRequestDetails?.attachement?.length>0 && (
            <span className={styles.Divattachment}>Attachments</span>
          )} */}
        {
           data?.serviceRequestDetails?.attachement?.map((attachment, index) => {
            return (
              <div className={styles.attachmentDiv}>
                <img src={IMAGES.attachment} className={styles.attachment} />
                <div className={styles.attachmentName}>{attachment.originalFileName}</div>
                <img src={IMAGES.downloadlogo} className={styles.download} onClick={() => downloadFile(attachment, index,onFileDownload)}  />
  
              </div>
            );
       
          })
        }       
        </div>
      </>
  );
};

const IPXDetailList = ({data,loading,onFileDownload,...props}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };
  
const navigate = useNavigate();

const back = (requestId) => {
  navigate(`/incident-management`);
}
  return (
     loading?(
      <div className={styles.comentsLoader}>
      <Loader />
      </div>
    ):(
      <div className={styles.headero}>
      <div className={styles.back}>
           <span><img className={styles.backbutton} src={IMAGES.backarrow} onClick={()=>back()} /></span><span className={styles.id}>{data?.serviceRequestId}</span><span className={`${styles.status} ${styles[data?.status?.value]}`}>{data.status?.label}</span>
      </div>
    <div className={styles.collapsibleContainer}>
      <div className={styles.collapsible}><span className={styles.issue}></span><span className={`${styles.summarydata} ${styles.ipxborder}`}> {data.serviceRequestDetails?.title}</span>
        {/* <div className={styles.titleContainer}> */}
         <ViewLessData data={data}/>
        {/* </div> */}
        {!isOpen && (
          <button type="button" className={styles.open} onClick={toggleContent}>
            View More
            <img
              className={styles.arrowdown}
              src={IMAGES.arrowdown}
              alt="arrow"
            />
          </button>
        )}
      </div>
      <div className={`${styles.content} ${isOpen ? styles.active : ""}`}>
        <div className={styles.contentInner}>
          <ViewMoreData data={data}  onFileDownload={onFileDownload}/>
        </div>
        {isOpen && (
          <button
            type="button"
            className={styles.close}
            onClick={toggleContent}
          >
            View Less
            <img className={styles.arrowup} src={IMAGES.arrowup} alt="arrows" />
          </button>
        )}
      </div>
    </div>
    </div>
  )
    )

  };

const IVCDetailList = ({ data,onFileDownload,loading,...props }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleContent = () => {
      setIsOpen(!isOpen);
    };
    
  const navigate = useNavigate();

  const back = (requestId) => {
    navigate(`/incident-management`);
  }
  const downloadFile = (file, index,onDownload) => {   
    onDownload(file)
  }
  
    return (
      loading?(
        <div className={styles.comentsLoader}>
        <Loader />
        </div>
      ):(
        <div className={styles.headero}>
        <div className={styles.back}>
             <span><img className={styles.backbutton} src={IMAGES.backarrow} onClick={()=>back()} /></span><span className={styles.id}>{data.serviceRequestId}</span><span className={`${styles.status} ${styles[data?.status?.value]}`}>{data.status?.label}</span>
        </div>
      <div className={styles.collapsibleContainer}>
        <div className={styles.collapsible}>
        {/* <div className={styles.summary}><span className={styles.issue}>Title:</span><span className={styles.summarydata}> {data.serviceRequestDetails?.title}</span></div> */}
          <div className={styles.ivcContainer}>
            <div className={styles.titleContainer}>
            <div className={`${styles.DetailsDiv} ${styles.sepration}`}>Service Type <span className={styles.data}> {data.serviceRequestType?.label}</span></div>
            <div className={`${styles.DetailsDiv} ${styles.borderPadding}`}>Submitted On <span className={styles.data}> {formatDate(data?.createdAt)}</span></div>
            </div>
        <div className={styles.ivcDetailsDiv}>Description <span className={styles.data}>
          <ExpandableText text={ data.serviceRequestDetails?.problemStatement} /></span></div>
          {data?.serviceRequestDetails?.attachement?.length>0 && (
            <span className={styles.Divattachment}>Attachments</span>
          )}
        <div className={styles.attachmentDetailsDiv}>
        {/* {data?.serviceRequestDetails?.attachement?.length>0 && (
            <span className={styles.Divattachment}>Attachments</span>
          )} */}
        {
           data.serviceRequestDetails?.attachement?.map((attachment, index) => {
            return (
              <div className={styles.attachmentDiv}>
                <img src={IMAGES.attachment} className={styles.attachment} />
                <div className={styles.attachmentName}>{attachment.originalFileName}</div>
                <img src={IMAGES.downloadlogo} className={styles.download} onClick={() => downloadFile(attachment, index,onFileDownload)}  />
  
              </div>
            );
          })
        }  
        </div>
          </div>   
        </div>
      </div>
      </div>
    )
    )
  };
  const CommentDetail = ({data, onFileDownload}) => {
    const [isExpanded, setIsExpanded] = useState(false);
  
    const handleToggle = () => {
      setIsExpanded(!isExpanded);
    };
  
    const text = data?.commentMessage
    const previewText = text?.slice(0, 100);
  
    function getInitials(name) {
      return name.split(' ').map(word => word[0].toUpperCase()).join('');
    }
  
    const downloadFile = (file, index,onDownload) => {   
      onDownload(file)
    }
    const showViewMore = text.length>100
  
    return (
      <div className={styles.commentContainer}>
        <div className={styles.initalshead}>
          <div className={styles.initaltex}>
            <span className={styles.inital}>{getInitials(data?.createdBy?.name)}</span>
            <span className={styles.initatname}>{data?.createdBy?.name}</span>
            {(!isExpanded && showViewMore && data?.commentAttachments?.length>0)? (
              <img className={styles.infoattach} src={IMAGES.attachinfo} />
            ) : (
              <></>
            )}
            {/* <span className={styles.warning}><img className={styles.circleinfo}src={IMAGES.infoCircle}/>Respond within 48 hours, or the ticket will be auto closed.</span> */}
          </div>
          <div className={styles.attachments}>
            Posted On: <span className={styles.date}>
              {formatDate(data?.createdAt)}
            </span>
          </div>
        </div>
        <div className={styles.comment}>
          {isExpanded ? text : `${previewText}${showViewMore?"...":""}`}
          {showViewMore&&(
              <span className={styles.viewMore} onClick={handleToggle}>
              {isExpanded ? " View Less" : " View More"}
            </span>
          )}
        </div>
        {((isExpanded && showViewMore) || (data?.commentAttachments?.length>0 && !showViewMore)) ? (
          <div className={styles.parent}>
          {data?.commentAttachments?.map((item,index)=>
          <div className={styles.commentattachment} key={index}>
         <img className={styles.attachlogo} src={IMAGES.attachment} />
         <span className={styles.filename}>{item?.originalFileName}</span>
         <img className={styles.attachlogo} src={IMAGES.downloadfile} onClick={() => downloadFile(item, index,onFileDownload)}  />
         </div>
        )}
        </div>  
        ) : (
          <></>
        )}
      </div>
    );
  };


const ServiceRequestDetail=(props)=> {
console.log("props",props)
  let { requestId } = useParams();
  useEffect(() => {
    props.getServiceDetail(requestId);
    let next=null;
    props.getServiceComments(requestId,next);
    window.scrollTo(0,0);
    return () => {
    props.clearServiceComments();
  };
  }, [requestId]);

  const fetchData = () => {
    if (props.next) {
      props.getServiceComments(requestId,props.next);
    }
  };


  const customerType = props?.serviceDetails?.serviceRequestType?.value;

  const onFileDownload=(file)=>{   
    
    props.downloadFile(file.originalFileName,file.uniqueFileName).then((result)=>{
        if(result.status){
          const fileData=result.file
          if(fileData?.length>0){
                window.open(fileData?.[0]);
          }
        }else{
          alert("Error while downloding file!");
        }
      }).catch((error)=>{
        alert("Something went wrong!")
      })
    }
  return (
    <Container>
      <Banner image={IMAGES.banner} text={"Service Details"}></Banner>
      <ChildContainer>
       
      </ChildContainer>
        <ChildContainer>
            {customerType == "ipx_service" ?<IPXDetailList data={props.serviceDetails} loading={props.loading}onFileDownload={onFileDownload}/>: customerType == "ivc_service" ?
            <IVCDetailList data={props.serviceDetails}  loading={props.loading} onFileDownload={onFileDownload}/>: <div className={styles.comentsLoader}><Loader /></div>}
           <div  className={styles.listhead}>
                 <div  className={styles.initaltext}>Comment History</div>
                 {props?.serviceDetails?.status?.value!==STATUS_ENUM.CLOSED&& !props.loading&&(
                  <SectionGuard canAccess={CAN(Action.CREATE_COMMENT,Resource.SERVICE_REQUESTS)}>
                  <div> <AddServiceComment source={'incDetail'} data={props.serviceDetails} /></div> 
                  </SectionGuard>
                 )}
           </div>
           {props.isCommentsLoading && props.comments.length == 0? (
          <div className={styles.comentsLoader}>
            <Loader />
          </div>
      ) : props.comments.length == 0 ? (
        <div className={styles.Emptydiv}>No Records Found</div>
      ) : (
        <InfiniteScroll
          dataLength={props?.comments?.length} 
          next={() => {
            fetchData();
          }}
          hasMore={props.next}
          loader={<Loader />}
        >
          {props?.comments?.map((item) => (
            <CommentDetail data={item} onFileDownload={onFileDownload}/>
          ))}
        </InfiniteScroll>
      )}
        </ChildContainer>
  
    </Container>
  )
};

export default ServiceRequestDetail;
