import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, ChildContainer } from "../../../components/Container/Containers";
import styles from "./service.module.css";
import { CommentContainer, CommentCreator, CommentContent, AttachmentContainer, Attachment } from "../../../components/comment/comment";
import downloadlogo from '../../../assets/images/downloadlogo.png';
import attachmentlogo from '../../../assets/images/attachmentlogo.png';
import { SERVICE_OPTIONS, SERVICE_STATUS_OPTIONS } from "../../../utils/constants/serviceConstants";
import SideNav from "../../fileAttachment/FileAttachment";
import {
  FormFieldLabel as Label,
} from "../../../components/label/Labels";
import DropDown from "../../../components/dropDown/DropDown";
import { Banner } from "../../../components/banner/Banners";
import { Breadcrumbs } from "../../../components/breadcrumbs/Breadcrumbs";
import { formatSecondsToDate } from "../../../utils/dateTime";
import IMAGES from "../../../assets/images/images";
import { Sidebar } from "../../../components/sidebar/Sidebar";
import Comment from "../comment/comment.container";
import comment from '../../../assets/images/comment.png'
import { USER_TYPE } from "../../../utils/constants/userConstants";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../../components/loader/Loader";
import ServiceStatus from "../serviceStatus/serviceStatus.container";
import CAN from '../../../casl/can';  
import {Action , Resource} from '../../../casl/constants'; 
import SectionGuard from "../../../hoc/guards/SectionGuard";
import { isString } from "../../../utils/utils";





const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};
let obj = {
  width: "300px"
};

const ServiceComment = ({ data, key,onFileDownload,isDownloadStart}) => {
  return (
    <CommentContainer id={data.id}>
      <CommentCreator creatorName={isString(data.createdBy)?data.createdBy:data.createdBy?.name} createdDate={formatSecondsToDate(data.createdAt)} />
      <CommentContent comment={data.comment} />
      <Attachments isDownloadStart={isDownloadStart} onFileDownload={onFileDownload} attachments={('files' in data)? data.files : []} />
    </CommentContainer>
  );
}

const Attachments = ({ attachments,onFileDownload,isDownloadStart }) => {

  const downloadFile = (file, index,onDownload) => {
     // const fileName="Attachment "+Number(index+1);
      onDownload(file)
    }
    
  return (
    <AttachmentContainer>
      <div className={styles.attachmentHeader}>Attachments</div>
      <div className={styles.attachmentContainer}>
      {attachments.length == 0 ? (
        <div>No Records Found</div>
      ) : (
        attachments.map((attachment, index) => {
          return (
            <div className={styles.attachmentDiv} title={attachment?.originalFileName}>
              <img className={styles.attachlogo} src={attachmentlogo} />
              <div className={styles.attachmentName}>{attachment?.originalFileName}</div>
              {
                  isDownloadStart?
                  <Loader loaderStyle={styles.fileDownloadLoader}/>:
                  <img src={downloadlogo} className={styles.download} onClick={() => downloadFile(attachment, index,onFileDownload)} />
              }
            </div>
          );
        })
      )}
      </div>
    </AttachmentContainer>
  );
}

const DetailContainer = ({ data, serviceOption, isDownloadStart,isInternalUser, onFileDownload }) => {
  const downloadFile = (file, index,onDownload) => {   
    onDownload(file)
  }
  const obj = SERVICE_STATUS_OPTIONS.find(({ value }) => value ===data.status);
  return (
    <div className={styles.topSection}>
      <div className={styles.topSectionDiv}>
        <div className={styles.requestorDetailsDiv}><span>Requestor Name:</span><span>{isString(data?.createdBy)?data?.createdBy:data.createdBy?.name}</span></div>
        <div className={styles.requestorDetailsDiv}><span>Demand ID:</span><span>{data?.requestId}</span></div>
        <div className={styles.requestorDetailsDiv}><span>Region:</span><span>{data?.locationDetails?.serviceRegion?.label}</span></div>
        <div className={styles.requestorDetailsDiv}><span>Country:</span><span>{data?.locationDetails?.serviceCountry?.label}</span></div>        
      </div>
      <div className={styles.bottomSectionDiv}>
      <div className={styles.serviceDetailDiv}><span>Status:</span><span>{obj?.label}</span></div>
        <div className={styles.serviceDetailDiv}><span>Service Name:</span><span>{data?.service}</span></div>

        {
          Object.entries(serviceOption).map((option, index) => {
            return (
              <div className={styles.serviceDetailDiv}><span>{SERVICE_OPTIONS[option[0]]}:</span><span>{(typeof option[1] === 'object' && option[1] !== null) ? option[1].toString() : option[1]}</span></div>
            );
          })
        }
      </div>
      {data.comment? <div className={styles.serviceDescriptionDiv}><span>Comment:</span><span>{data?.comment}</span></div>:''}
      <br></br>
      {data.files? <div><span>Attachments:</span></div>:''}
      <div className={styles.serviceAttachmentDiv}>  
        {
           data.files?data.files.map((attachment, index) => {
            return (
              <div className={styles.attachmentDiv} title={attachment?.originalFileName}>
                <img className={styles.attachlogo} src={attachmentlogo} />
                <div className={styles.attachmentName}>{attachment?.originalFileName}</div>
                {
                  isDownloadStart?
                  <Loader loaderStyle={styles.fileDownloadLoader}/>:
                  <img src={downloadlogo} className={styles.download} onClick={() => downloadFile(attachment, index,onFileDownload)}  />
                }
              </div>
            );
          }) : <></>
        }
       
      </div>


    </div>
  );
}

const CommentBox = (props) => { 
  return (
    <img className={styles.commentIcon} src={comment} onClick={props.openCommentForm} />
  );
}
const StatusIcon = (props) => { 
  return (
    <img className={styles.editIcon} src={IMAGES.editIcon} onClick={props.openServiceStatusPopup} />
  );
}
const ServiceDetailHeader = ({data, showCommentPopup,showServiceStatusPopup, ...props}) => {
  return (
    <div className={styles.serviceDetailHeader}><div>Demand Details</div>
      <div className={styles.iconsContainer}>
      <SectionGuard canAccess={CAN(Action.CREATE_COMMENT, Resource.DEMANDS)}>
        <CommentBox openCommentForm={() => {
          return showCommentPopup(data);
        }} />
      </SectionGuard>
        <SectionGuard canAccess={CAN(Action.STATUS_UPDATE, Resource.DEMANDS)}>
            <StatusIcon openServiceStatusPopup={() => {
              return showServiceStatusPopup(data);
            }} />
      </SectionGuard>
      </div>
    </div>
  );

}

const ServiceCommentsList = ({serviceComments,onFileDownload,fetchData,hasNext,isCommentsLoading,isDownloadStart}) => {
  return (
    <div className={styles.commentMainContainer}>
      { isCommentsLoading && serviceComments.length == 0?
       <Loader/>:
      serviceComments.length == 0 ? (
        <div>No Records Found</div>
      ) : (
        <InfiniteScroll
          dataLength={serviceComments?.length} //This is important field to render the next data
          next={() => {
            fetchData();
          }}
          hasMore={hasNext}
          loader={
              <Loader/>
          }
        >
          {
           serviceComments.map((comment, index) => {
            return (
              <ServiceComment isDownloadStart={isDownloadStart} data={comment} key={index} onFileDownload={onFileDownload}/>
            );
          })
          }
        </InfiniteScroll>
      )}
    </div>
  )
}
const Previousconfiguration = ({data}) => { 
  return (
    <div className={styles.previousMainContainer}>
         <div className={styles.previousContainer}>VLAN ID: <span>{data?.vlan}</span></div>
          <div className={styles.previousContainer}>Router City: <span>{data?.router_location}</span></div>
          <div className={styles.previousContainer}>Router Name:  <span>{data?.pe_ref}</span></div>
          <div className={styles.previousContainer}>Allocated Capacity (Mbps): <span>{data?.physical_capacity_gbps}</span></div>
          {/*<div className={styles.previousContainer}>Service Id: <span>{data.service_id}</span></div>*/}
    </div>
  )
}


const ServiceDetailsContainer = ({servicesDetail,isDownloadStart,isCommentsLoading, serviceOption, isInternalUser, serviceComments,onFileDownload,fetchData,hasNext}) => {
  return (
    <div className={styles.serviceDetailContainer}>
        {(servicesDetail && servicesDetail.service_option) ?
          <DetailContainer data={servicesDetail} isDownloadStart={isDownloadStart} serviceOption={serviceOption} isInternalUser={isInternalUser} onFileDownload={onFileDownload} />
          : " No record found"}
        {servicesDetail?.additional_info && Object.keys( servicesDetail.additional_info).length > 0 ? (
          <div className={styles.Previousconfiguration}>
             <div className={styles.PreviousHeader}>Previous Configuration</div>
              <Previousconfiguration data={servicesDetail.additional_info} />
          </div>  
        ) : <></>}
        <div className={styles.commentSection}>
          <div className={styles.commentHeader}>Comments</div>
          <ServiceCommentsList isDownloadStart={isDownloadStart} isCommentsLoading={isCommentsLoading} serviceComments={serviceComments} onFileDownload={onFileDownload} fetchData={fetchData} hasNext={hasNext}/>
        </div>
      </div>
    );
}

const Service = (props) => {
  
  let { requestId } = useParams();
  const serviceDetail = props.servicesDetail; 
  const serviceOption = serviceDetail.service_option;
  const serviceComments = props.comments;
  const [isDownloadStart,setIsDownloadStart]=useState(false);
  let [serviceData, setServiceData] = useState({"id" :requestId}); 
  let _label=props.myRights?.userType==USER_TYPE.INTERNAL?"Connectivity":"My Connectivity";
  const breadcrumbsOption = [
    { label: _label, link: "/connectivity" },
    { label: "Demand Details", link: "" }
  ];
  
  useEffect(() => {
    props.getServiceDetail(requestId);
    let next=null;
    props.getComments(requestId,next);
    window.scrollTo(0,0);
  }, []); 

  
 

 
  
  const closeCommentsSidebar = () => {
    props.closeCommentForm();
  }
  const showCommentPopup=(data)=>{ 
    const serviceId = {"id" : requestId};    
    data = {
      ...data,
      ...serviceId
  };  
    setServiceData({data});
    props.openCommentForm();
  }
  const showServiceStatusPopup=(data)=>{  
    const serviceId = {"id" : requestId};    
      data = {
        ...data,
        ...serviceId
    };  
    setServiceData({data});
    props.openServiceStatusForm();
  }
  const onFileDownload=(file)=>{   
    setIsDownloadStart(true);
  props.downloadFile(file.originalFileName,file.uniqueFileName).then((result)=>{
      if(result.status){
        /*const fileData=result.file
        if(fileData?.length>0){
              window.open(fileData?.[0]);
        }*/
      }else{
        alert("Error while downloding file!");
      }
      setIsDownloadStart(false);
    }).catch((error)=>{
      alert("Error while downloding file!");
      setIsDownloadStart(false);
    })
  }

  const fetchData=()=>{
      if(props.next){
        props.getComments(requestId,props.next);
      }
  }
  

  return (
    <Container>
      <Banner image={IMAGES.banner} text={"Demand Details"}></Banner>
      <ChildContainer>
        <Breadcrumbs options={breadcrumbsOption} />
      </ChildContainer>
      <ChildContainer>
      <ServiceDetailHeader data={serviceDetail} showCommentPopup={showCommentPopup} showServiceStatusPopup={showServiceStatusPopup} />
      {/* <Sidebar
        isOpen={props.isCommentFormOpen}
        onClose={closeCommentsSidebar}
        headerTxt={"Add comment"}
      > */}
      <Comment serviceData={serviceData}></Comment>
      <ServiceStatus openFrom={"ServiceDetails"} serviceData={serviceData} status={serviceData.data?.status}></ServiceStatus>

      {/* </Sidebar> */}
      <ServiceDetailsContainer isDownloadStart={isDownloadStart} isCommentsLoading={props.isCommentsLoading} fetchData={fetchData} hasNext={props.next} servicesDetail={serviceDetail} serviceOption={serviceOption} isInternalUser={props.myRights?.userType===USER_TYPE.INTERNAL?true:false} serviceComments={serviceComments} onFileDownload={onFileDownload} />
      </ChildContainer>
  
    </Container>
  )
};

export default Service;
