import { connect } from "react-redux";
import OnboardingRequestDetail from "./onboardingRequestDetail";
//import { getRequestDetails } from "../../../../../redux/actions/userManagement/onboardingDetails.action";
const mapStateToProps = (state) => {
console.log("stateDetail", state);

  return {
    requestDetails : state.onboardingRequest.onboardingDetail.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { 
    //  getRequestDetails: (requestId)=>{
    //   return dispatch(getRequestDetails(requestId))
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingRequestDetail);
