import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import {Wrapper, TextField, TextArea, SelectField ,FormTitle,ImageUpload} from "../controllers/controllers";
import * as Yup from "yup";
import styles from "../controllers/controllers.module.css";
import { EditButton, Submit } from "../../../../components/button/buttons";
import IMAGES from "../../../../assets/images/images";
import {connect} from "react-redux";
import {setIncidentPreviewMode} from "../../../../redux/actions/incidentManagement/raiseIncident.action";
import { ADD_TICKET_ACTION, Priority_Options} from "../../../../utils/constants/incidentConstants";
import {getLabelFromValue} from "../../../../utils/arrayUtils"
import {SubmitIncident} from "../../../../redux/actions/incidentManagement/raiseIncident.action";
import {openAlertPopup} from "../../../../redux/actions/Alert/alert.action";
import {getIncidentList} from "../../../../redux/actions/incidentManagement/incidentList.action";
import { downloadFiles } from "../../../../redux/actions/fileAttachment/fileAttachment.actions";

const GrxrSignallingIssueMultipleForm = ({ service_type, issue_type, failure_type,parentFormData,actionType,incidentData, ...props }) => {
    const [isSubmit, setIsSubmit] = useState(false);
    const onFileDownload=(file)=>{
        props.downloadFile(file.originalFileName,file.uniqueFileName).then((result)=>{
            if(!result.status){
                alert("Error while downloding file!");
            }
        }).catch((error)=>{
        alert("Something went wrong!")
        })
    }
    let initialValues ={}
    if(actionType===ADD_TICKET_ACTION.INCIDENT_DETAILS){
        initialValues = {
            issue_summary:incidentData?.issueDetails?.title,
            priority: incidentData?.issueDetails?.priority,
            problem_statement: incidentData?.issueDetails?.problemStatement,
            home_network: incidentData?.issueDetails?.homeNetworkPLM,
            visited_network: incidentData?.issueDetails?.visitedNetworkPLMN,
            msi_MSISDN:incidentData?.issueDetails?.mobileSubscriberIdentity,
            error_received: incidentData?.issueDetails?.errorReceived,
            date_time: incidentData?.issueDetails?.issueStartedDateTime,
            source_ip: incidentData?.issueDetails?.sourceIPDetails,
            destination_ip: incidentData?.issueDetails?.destinationIPDetails,
            trace_text: incidentData?.issueDetails?.tracesText,
            trace_file: incidentData?.issueDetails?.tracesAttachment,
            additional_information: incidentData?.issueDetails?.additionalInformation,
        };
    }else{
        initialValues = {
            issue_summary:'',
            priority:'',
            problem_statement:'',
            home_network:'',
            visited_network:'',
            msi_MSISDN: '',
            error_received:'',
            date_time:'',
            source_ip:'',
            destination_ip:'',
            trace_text:'',
            trace_file: [],
            additional_information: '',
        };
    }

    const validationSchema = Yup.object().shape({
        issue_summary: Yup.string().max(100, "Issue summary must be at most 100 characters").required("Required"),
        priority: Yup.string(),
        problem_statement:Yup.string().max(1000, "Problem statement must be at most 1000 characters").required("Required"),
        home_network:Yup.string().required("Required"), //.matches(/^[a-zA-Z0-9]*$/,'Only alphanumeric characters are allowed.')
        visited_network:Yup.string().required("Required"), //.matches(/^[a-zA-Z0-9]*$/,'Only alphanumeric characters are allowed.')
         imsi_source:Yup.string().required("Required"),
        error_received:Yup.string(),
        date_time:Yup.string().required("Required"),
        source_ip:Yup.string().required("Required"),
        destination_ip:Yup.string().required("Required"),
        trace_text:Yup.string().max(1000, "Traces must be at most 1000 characters").test(
            {
                name:'Required',
                message:'At least one of the fields Traces description or supporting attachments required',
                test:function(value){
                    let file= this.resolve(Yup.ref('trace_file'));
                    let text=value;
                    return text?.trim().length > 0 || file.length > 0
                }
            }
        ),
        trace_file:Yup.array().max(3).test(
            {
                name:'Required',
                message:'At least one of the fields Traces description or supporting attachments required',
                test:function(value){
                    let text= this.resolve(Yup.ref('trace_text'));
                    let file=value;
                    return text?.trim().length > 0 || file.length > 0
                }
            }
        ),
        additional_information:Yup.string().max(1000, "Text must be at most 1000 characters")
    })
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
                if(props.isIncidentPreviewModeOn){
                   setIsSubmit(true);                                  
                let obj = {
                    incidentServiceType: parentFormData.service_type,
                    incidentFaultType: parentFormData.issue_type,
                    failureRelatedType: parentFormData.failure_type,
                    impactedSubscriber: parentFormData.impacted_subscriber,
                    issueDetails: {
                        title: values.issue_summary,
                        problemStatement: values.problem_statement,
                        priority: values.priority,
                        issueStartedDateTime: values.date_time,
                        homeNetworkPLMN: values.home_network,
                        visitedNetworkPLMN: values.visited_network,
                        IMSIDetails: values.imsi_source,
                        errorReceived: values.error_received,
                        tracesTextOrIMSI: values.trace_text,
                        tracesTextOrIMSIAttachment: values.trace_file,
                        sourceIPDetails: values.source_ip,
                        destinationIPDetails: values.destination_ip,                  
                        additionalInformation: values.additional_information
                    }
                  };  
                
                props.addIncident(obj).then((result) => {        
                  if (result.status) {
                    props.openAlertPopup({
                      message: "New incident has been created.",
                      image: IMAGES.success
                    });          
                    setIsSubmit(false);
                    props.closeForm();
                    resetForm();
                    props.getIncidentList();
                  } else {
                    let error_msg = result?.error;
                    props.openAlertPopup({
                      message: error_msg,
                      image: IMAGES.error,
                      isFormOpen:true
                    });  
                     setIsSubmit(false);
                    // props.closeForm();
                  }
                })
                .catch((error) => {
                  props.openAlertPopup({
                    message: "Something went wrong.",
                    image: IMAGES.error,
                    isFormOpen:true
                  }); 
                  setIsSubmit(false);
                //  props.closeForm();
                });
            }else{
                props.setIncidentPreviewMode(true);
            }   
            }}
        >
            {({ isValid, dirty, setFieldValue, values, handleChange, handleSubmit, handleBlur, resetForm }) => (
                <>
                    <FormTitle
                            title={actionType===ADD_TICKET_ACTION.INCIDENT_DETAILS?null:(props.isIncidentPreviewModeOn?"Please Check The Details":"Please Provide The Details")}
                    />
                    <Form 
                        id="diameter_signalling_issue_form"
                        className={styles.form}
                        onSubmit={handleSubmit}
                    >
                        <TextField
                            label="Title"
                            type="text"
                            name="issue_summary"
                            isMandatory={true}
                            placeholder="Enter here"
                            isExpandedField={true}
                            showTooltip={true}
                            tooltipText={"Title"}
                            textLimit={"100 characters"}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                        <SelectField
                            label={"Priority"}
                            name="priority"
                            placeholder="Choose priority"
                            options={Priority_Options}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                            showTooltip={true}
                            tooltipText={"Priority"}
                            isExpandedField={false}
                            isMandatory={false}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                        <TextArea
                            label="Problem Statement"
                            name="problem_statement"
                            isMandatory={true}
                            placeholder="Enter the issue you are facing..."
                            isExpandedField={true}
                            showTooltip={true}
                            tooltipText={"Problem Statement"}
                            textLimit={"1000 characters"}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                        <TextField
                            label="Home Network (PLMN)"
                            name="home_network"
                            type="text"
                            isMandatory={true}
                            placeholder="Enter here"
                            isExpandedField={false}
                            showTooltip={true}
                            tooltipText={"Home Network (PLMN)"}
                            textLimit={null}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                         <TextField
                            label="Visited Network (PLMN)"
                            name="visited_network"
                            type="text"
                            isMandatory={true}
                            placeholder="Enter here"
                            isExpandedField={false}
                            showTooltip={true}
                            tooltipText={"Visited Network (PLMN)"}
                            textLimit={null}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />                                                                                                         
                      <TextField
                            label="IMSI Details - A Party"
                            name="imsi_source"
                            isMandatory={true}
                            type="text"
                            placeholder="Enter here"
                            isExpandedField={false}
                            showTooltip={true}
                            tooltipText={"IMSI Details - A Party"}
                            textLimit={null}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                        <TextField
                            label="Error Received"
                            name="error_received"
                            isMandatory={false}
                            type="text"
                            placeholder="Enter here"
                            isExpandedField={false}
                            showTooltip={true}
                            tooltipText={"Error Received"}
                            textLimit={null}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                         <TextField
                            label="Date & Time of Issue Started"
                            name="date_time"
                            isMandatory={true}
                            type="datetime-local"
                            placeholder="Enter here"
                            isExpandedField={false}
                            showTooltip={true}
                            tooltipText={"Date & Time of Issue Started"}
                            textLimit={null}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                         <TextField
                            label="Source IP Details"
                            name="source_ip"
                            isMandatory={true}
                            type="text"
                            placeholder="Enter here"
                            isExpandedField={false}
                            showTooltip={true}
                            tooltipText={"Source IP Details"}
                            textLimit={null}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                         <TextField
                            label="Destination IP Details"
                            name="destination_ip"
                            isMandatory={true}
                            type="text"
                            placeholder="Enter here"
                            isExpandedField={false}
                            showTooltip={true}
                            tooltipText={"Destination IP Details"}
                            textLimit={null}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                       
                        <TextArea
                            label="Traces or Attachments"
                            name="trace_text"
                            isMandatory={true}
                            placeholder="Enter the issue you are facing..."
                            isExpandedField={true}
                            showTooltip={true}
                            tooltipText={"Traces or Attachments"}
                            textLimit={"1000 characters"}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                        <ImageUpload
                            name="trace_file"
                            value={values.trace_file}
                            setFieldValue={setFieldValue}
                            isMandatory={true}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                            onFileDownload={onFileDownload}
                            actionType={actionType}
                        />
                         <TextArea
                            label="Additional Information"
                            name="additional_information"
                            isMandatory={false}
                            placeholder="Enter here"
                            isExpandedField={true}
                            showTooltip={true}
                            tooltipText={"Additional Information"}
                            textLimit={"1000 characters"}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                            />
                    </Form>
                    {actionType===ADD_TICKET_ACTION.INCIDENT_DETAILS?
                         <footer className={`${styles.footer} ${actionType===ADD_TICKET_ACTION.INCIDENT_DETAILS ?styles.closedetalpopup:""}`}>
                            <EditButton
                            className={styles.backButton}
                            title={"Close"}
                            style={styles.btn}
                            onClick={()=>{
                                props.setIncidentPreviewMode(false);
                                props.closeForm();
                            }}/>
                         </footer>:
                    <footer className={styles.footer}>
                        {
                           props.isIncidentPreviewModeOn? 
                           <EditButton
                            className={styles.backButton}
                            title={"Edit"}
                            style={styles.btn}
                            onClick={()=>props.setIncidentPreviewMode(false)}
                        />:<EditButton
                            className={styles.backButton}
                            title={"Cancel"}
                            style={styles.btn}
                            onClick={()=>{
                                props.setIncidentPreviewMode(false);
                                props.closeForm();
                            }}
                        />
                        }
                        <Submit
                            id="saveButton"
                            title={props.isIncidentPreviewModeOn?"Submit":"Preview"}
                            form="diameter_signalling_issue_form"
                            style={styles.btn}
                            isSubmitting={isSubmit}
                            // disabled={!isValid || !dirty}
                        />
                    </footer>
}
                </>
            )}

        </Formik>
    )

}

const mapStateToProps = (state)=>{   
    return {
        isIncidentPreviewModeOn:state.IncidentManagement.isIncidentPreviewModeOn    
    }
  }

const mapDispatchToProps = (dispatch)=>{
    return {
        setIncidentPreviewMode: (payload) => {
            return dispatch(setIncidentPreviewMode(payload))
        },
        addIncident: (payload) => { console.log("payload",payload);
        
            return dispatch(SubmitIncident(payload))
        },
        openAlertPopup: (payload) => {
            return dispatch(openAlertPopup(payload))
          },
        getIncidentList: (next=null)=>{
            return dispatch(getIncidentList(next))
        },
        downloadFile:(originalFileName,uniqueFileName)=>{
            return dispatch(downloadFiles(originalFileName,uniqueFileName))
        },
    }
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(GrxrSignallingIssueMultipleForm);

