import { BASE_URL } from "../configuration/general-config";

export {BASE_URL}
/** ***************************** HTTP VERBS ************************* */
export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';
export const PATCH = 'PATCH';

/******************************** HTTPS CODES */
export const HTTP_CODES = { UNAUTHORIZED : 401,BADREQUEST:400 }

/******************************** API Result Status *******************/
export const API_REQUEST_STATUS = {
    SUCCESS: 'success',
    FAILURE: 'failure'
}

/************************* END POINTS *******************/
export const RIGHTS_ME="/iam/rights/me";
export const SERVICES_LIST = "/serviceRequests";
export const LOCATIONS = "/serviceRequests/locations/list";
export const FETCH_CUSTOMERS_DROPDOWN = "/customers/all";
export const SERVICE = "/serviceRequests";
export const SERVICE_DETAIL = "/serviceRequests";
export const UPLOAD_ATTACHMENT = "/files/upload"; //"/comments/upload";
export const DOWNLOADS = "files/download";
export const COMMENT = "/comments";
export const DIAMETER_GRAPH1 = "/dashboards/diameter";
export const DIAMETER_GRAPH2 = "/dashboards/diameter";
export const SIGTRAN_GRAPH1 = "/dashboards/sigtran";
export const SIGTRAN_GRAPH2 = "/dashboards/sigtran";
export const IPX_POPS = "/genericInfo/vodafone-pops";
export const LIVE_SERVICES = "/serviceDetails";
export const DIA_FINALTYPE_FINALDIST ="/dashboards/filter-dia-graph2";
export const DIA_SIGNODE_CONNECTIONID = "/dashboards/filter-dia-graph1";
export const SIG_FINALTYPE_FINALDIST = "/dashboards/filter-sig-graph2";
export const SIG_SIGNODE_CONNECTIONID = "/dashboards/filter-sig-graph1";
export const GET_LIVE_SERVICES_FILTER_VALUES= "/serviceDetails/filters";
export const DOWNLOAD_SERVICES_LIST = "/serviceRequests/download";
export const DOWNLOAD_Live_SERVICES_LIST = "/serviceDetails/download";

/*-------Weather Maps------------------------------*/
export const GET_WEATHERMAP_DATA = "/weatherMap";
export const GET_WEATHERMAP_FILTER_VALUES= "/weatherMap/filters";

/*------------------------------------*/

export const SERVICES = "/serviceRequests/services/list";
/*-------------CONTACT US----------------------*/
export const  CONTACT_US= "/contactus";

/*-------------USER MANAGEMENT----------------------*/
export const IAM_RIGHTS = "/iam/rights";
export const IAM_USERS = "/iam/users";
export const IAM_FUNCTIONAL_ROLES = "/iam/functionalRoles";
export const LIST_CUSTOMER = "/customers"
export const VERIFY_EMAIL = "/user/verifyEmail"
export const RESET_PASSWORD = "/user/resetPassword"

export const ONBOARDING_COMMENTS = ""
/*-------------INCIDENT MANAGEMENT----------------------*/
export const IVCRequest = "/";
export const SERVICE_REQUEST = "/api/incident-management/v1/service-requests";
export const INCIDENT_REQUEST = "/api/incident-management/v1/incidents";
export const INCIDENT_LIST = "/api/incident-management/v1/incidents";
export const INCIDENT_COMMENTS = "/api/incident-management/v1/comments/incident";
export const SERVICE_LIST ="/api/incident-management/v1/service-requests";
export const SERVICE_COMMENTS ="/api/incident-management/v1/comments/service-request";
