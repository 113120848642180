import {ONBOARDING_ADD_COMMENT_FORM} from "../actionTypes"
import { addRequest  } from "../../../api/userManagement/onboardingAddComment.service";

const request = () => ({ type: ONBOARDING_ADD_COMMENT_FORM.REQUEST })
const failure = (error) => ({ type: ONBOARDING_ADD_COMMENT_FORM.FAILURE, error })
const success = () => ({ type: ONBOARDING_ADD_COMMENT_FORM.SUCCESS })

// const commentRequest = () => ({ type: FETCH_SERVICE_COMMENTS.REQUEST })
// const commentfailure = (error) => ({ type: FETCH_SERVICE_COMMENTS.FAILURE, error })
// const commentSuccess = (obj)=>({type: FETCH_SERVICE_COMMENTS.SUCCESS, obj})
// const resetComments = () => ({ type: RESET_SERVICE_COMMENTS });

export const AddOnboardingComment = (payload) => {
  return (dispatch, getState) => {
    dispatch(request());
    return addRequest(payload)
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve({res:res,status:true});

      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve({error:error?.message,status:false});
      })
  }
}


// export const getServiceComments = (requestId,next=null)=>{
//   return (dispatch, getState)=>{        
//       dispatch(commentRequest())
//       return fetchServiceComments(requestId,next)
//       .then(response => {
//         console.log("response",response);
        
//           let obj={
//               "data":response?.data,
//               "next":response?.next,
//               "isLoadMore": next?true:false
//           }
//           dispatch(commentSuccess(obj))
//           return Promise.resolve(true)
//       })
//       .catch(error => {
//           dispatch(commentfailure(error?.message))
//           return Promise.resolve(false)
//       })
//   }
// }

// export const clearServiceComments = () => {
//   return (dispatch) => {
//     dispatch(resetComments());
//   };
// }