import ServiceRequestDetail from "./serviceRequestDetail";
import {connect} from "react-redux";
import { getServiceDetail } from "../../../../redux/actions/incidentManagement/serviceDetails.action";
import { downloadFiles } from "../../../../redux/actions/fileAttachment/fileAttachment.actions";
import { getServiceComments, clearServiceComments } from "../../../../redux/actions/incidentManagement/serviceComment.action";
const mapStateToProps = (state) => {
 console.log("State",state.serviceDetails.loading)
    return {
        serviceDetails: state.serviceDetails.serviceDetails,    
        loading: state.serviceDetails.loading, 
        next: state.serviceComment.next,
        comments: state.serviceComment.comments,
        isCommentsLoading:state.serviceComment.isLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getServiceDetail: (requestId)=>{
            return dispatch(getServiceDetail(requestId))
          },
          downloadFile:(originalFileName,uniqueFileName)=>{
            return dispatch(downloadFiles(originalFileName,uniqueFileName))
          },
          getServiceComments:(requestId,next=null)=>{
            return dispatch(getServiceComments(requestId,next))
          },
          clearServiceComments:()=>{
            return dispatch(clearServiceComments())
          },
      
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequestDetail);
