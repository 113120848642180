/** *****************************Actions************************* */
export const Action = {
  CREATE: 'create',
  LIST: 'list',
  GET: 'get',
  UPDATE: 'update',
  DELETE: 'delete',
  DOWNLOAD: 'download',
  STATUS_UPDATE: 'statusupdate',
  UPLOAD_ATTACHMENT: 'uploadAttachment',
  CREATE_COMMENT: 'createComment',
  DOWNLOAD_ATTACHMENT: 'downloadAttachment',
  LIST_COMMENT: 'listComment',
  GET_COMMENT: 'getComment',
  UPLOAD_COMMENT_ATTACHMENT: 'uploadCommentAttachment',
  DOWNALOD_COMMENT_ATTACHMENT: 'downloadCommentAttachment',
  APPROVER: 'approver'
};

/** *****************************Resources************************* */
export const Resource = {
  IPX_POP: "ipxPoP",
  LIVE_SERVICES: "liveServices",
  DEMANDS: "demands",
  CUSTOMER_NAMES: 'customerNames',
  DIAMETER: "diameter",
  SIGTRAN: "sigtran",
  WEATHERMAPS: "weathermaps",
  QUERY: "query",
  INCIDENTS: "incidents",
  SERVICE_REQUESTS: "serviceRequests",
  CUSTOMERS:"customers",
  EMAIL_VERIFICATION_LINK: 'emailVerificationLink',
  FUNCTIONAL_ROLES: 'functionalRoles',
  RIGHTS: 'rights',
  CUSTOMER_ONBOARDING_REQUEST: 'customerOnboardingRequest',
  CUSTOMER_ACCESSR_REQUEST: 'customerAccessRequest',
  SERVICE_LOCATIONS: "serviceLocations",
  SERVICE_TYPE: "serviceType"
};

