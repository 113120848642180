import { FETCH_REQUEST_DETAIL } from "../actionTypes"
import { fetchrequestDetail } from "../../../api/userManagement/onboardingDetails.service"

const requestDetailRequest = ()=>({type: FETCH_REQUEST_DETAIL.REQUEST})
const requestDetailFailure = (error)=>({type: FETCH_REQUEST_DETAIL.FAILURE, error})
const requestDetailSuccess = (obj)=>({type: FETCH_REQUEST_DETAIL.SUCCESS, obj})

export const getRequestDetails = (requestId)=>{
    return (dispatch, getState)=>{        
        dispatch(requestDetailRequest())
        return fetchrequestDetail(requestId)
        .then(response => {
         //   console.log("responsedetail", response);
            
            dispatch(requestDetailSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(requestDetailFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}
