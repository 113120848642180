import React,{useEffect} from 'react'
import styles from './myServiceRequest.module.css';
import IMAGES from '../../../../assets/images/images';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../../../components/loader/Loader";
import moment from 'moment';
import { formatDate } from '../../../../utils/arrayUtils';

const ServiceRequestList = ({ data }) => {
  // {console.log("props",data?.isServiceDeskCommentReceived)}
  const navigate = useNavigate();
  const gotosrDetail = (requestId) => {
    navigate(`/ServiceRequestDetail/${requestId}`);
  }
    return (
        <div className={styles.collapsibleContainer}>
        <div className={styles.collapsible}>
        <div className={styles.header}>
          <div> <span  className={styles.SRid} onClick={()=>gotosrDetail(data.id)}>{data.serviceRequestId}</span>
                <span className={`${styles.status} ${styles[data?.status?.value]}`}>
                      {data.status.label}
                </span>
          </div>
          <div className={styles.onhoverdiv}>
            <div>
              {
                <span className={styles.notificationmain}>
                  {(data.isServiceDeskCommentReceived)&&(
                  <div className=""><span className={styles.commentnotification}></span> <img className={styles.newcomment}src={IMAGES.newcomment} alt="new comment"/></div>
                  )}
                </span>
              }
            </div>
          </div>
        </div>
         <div className={styles.summary}><span className={styles.issue}></span><span className={styles.summarydata}> {data?.serviceRequestDetails?.title}</span></div>
          <div className={styles.titleContainer}>
          <div className={styles.DetailsDiv}>Service Type:<span className={styles.data}> {data.serviceRequestType.label}</span></div>        
          <div className={styles.border}></div>
          <div className={styles.DetailsDiv}>Submitted On:<span className={styles.data}> {formatDate(data.createdAt)}</span></div> 
           </div>
        </div>
       
      </div>
   );
  };
  

const ServiceRequestViewer = (props) => {
const data = props.serviceList.data;

 useEffect(() => {
  props.getServiceList();
}, []);

const fetchData = () => {
  if (props.serviceList.next) {
    props.getServiceList(props.serviceList.next);
  }
};
    return (
      <div>
      {props.serviceList.isLoading && props.serviceList.data.length == 0 ? (
        <Loader />
      ) : props.serviceList.data.length == 0 ? (
        <div className={styles.Emptydiv}>No Records Found</div>
      ) : (
        <InfiniteScroll
          dataLength={props.serviceList.data?.length}
          next={() => {
            fetchData();
          }}
          hasMore={props.serviceList.next}
          loader={<Loader />}
        >
           {data.map((item) => (
            <ServiceRequestList data={item} />
          ))}
        </InfiniteScroll>
      )}
    </div>
    )
}
  
export default ServiceRequestViewer;

