import { BASE_URL, GET, } from "../constants";
import { statusParsing, jsonParsing } from "../utils";
import { getAccessToken } from '../../authActions/authActions';

const obj = [
    {
      "id": "su5reyzXWgu7UoQzRWqM",
      "createdAt": 1727428648,
      "createdBy": {
        "user_id": "mEyWHi3TuQZizc4Z1YlHKXrkqWw2",
        "name": "BICS admin",
        "email": "bics_user1@email.com"
      },
      "requesDetails": {
      
        "acContact": {
          "label": "Account Manager Contact",
          "value": "dinesh@email.com"
        },
        "subon": {
          "label": "subbmited on",
          "value": "2024-09-03 13:50"
        },
        "serviceType": {
          "label": "Telefonica Global Soltuions",
          "value": "ipx_service"
        },
        "customerType": {
          "label": "Customer Primary Contact ",
          "value": "john@email.com"
        }
      },
      "requestId": "REQ-20240927-5",
      "serviceType": {
        "label": "Telefonica Global Soltuions",
        "value": "ipx_service"
      },
      "tadigId": "IPX Services  ",
      "modifiedAt": 1727436537,
      "modifiedBy": {
        "acName": "Commercial Manager",
        "account_manager": "Account Manager",
        "email": "dinesh@email.com"
      },
      "status": {
        "label": "New",
        "value": "new"
      }
    },
   
  ];

export const fetchOnboardingRequestList = async (next) => {
 return obj;
//   const token = await getAccessToken();
//   let url = `${BASE_URL}${}`;

// let array = [
//   {
//     key:"next",
//     value:next
//   } // download true
// ]  

// let queryString=``;
// array.forEach((item)=>{
//   if(item.value){
//     if(queryString){
//       queryString=queryString+`&${item.key}=${item.value}`
//     }else{
//       queryString=`${item.key}=${item.value}`
//     }
//   }
// })
// if(queryString){
//   url=`${url}?${queryString}`;
//   url = url.replace ("filter=&", "");
// }
//   return fetch(url, {
//     method: GET,
//     headers: {
//       "Content-Type": "application/json",
//       userAuth: `bearer ${token}`,
//     },
//   })
//     .then(statusParsing)
//     .then(jsonParsing);
};
