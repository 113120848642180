import MyIncidentViewer from "./myIncidentViewer";
import { connect } from "react-redux";
import { getIncidentList } from "../../../../redux/actions/incidentManagement/incidentList.action";

const mapStateToProps = (state) => {
  return {
    incidentList: state.incidentList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIncidentList: (next=null)=>{
      return dispatch(getIncidentList(next))
  },

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyIncidentViewer);
